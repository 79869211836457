import './header.scss';

import React, { useState } from 'react';
import { Translate, Storage, logInfo } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink as Link } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, SiteMenuItem } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';

import { NavItem,NavLink } from 'reactstrap';
import { PhysicianMenu } from '../menus/physician';
import { PatientMenu } from '../menus/patient_menu';
import { StaffMenu } from '../menus/staff';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isPhysician: boolean;
  isPatient: boolean;
  isStaff:boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  currentLocale: string;
  onLocaleChange: Function;
  firstName:string;
  lastName:string;
  userId:number;
  login:string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header">
               {/* {renderDevRibbon()}  */}
      <LoadingBar className="loading-bar" />
      <Navbar light expand="lg" fixed="top">
        <div className='container'>
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        <Collapse  isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ml-auto" navbar >
            <Home  toggle={setMenuOpen} path="/" />
            <SiteMenuItem toggle={setMenuOpen} name="ABOUT"  path="/about"/>
            <SiteMenuItem toggle={setMenuOpen} name="SERVICES" path="/service"/>
            <SiteMenuItem toggle={setMenuOpen} name="DEPARTMENTS" path="/departments"/>
            <SiteMenuItem toggle={setMenuOpen} name="DOCTORS" path="/doctors"/>
            <SiteMenuItem toggle={setMenuOpen} name="CONTACT" path="/contact"/>
            {props.isAuthenticated&&props.isPatient && <PatientMenu toggle={setMenuOpen}  />}
            {props.isAuthenticated&&props.isPhysician && <PhysicianMenu toggle={setMenuOpen} />}
            {props.isAuthenticated&&props.isStaff && <StaffMenu toggle={setMenuOpen} />}
            {props.isAuthenticated&&props.isAdmin && <EntitiesMenu toggle={setMenuOpen} />}
            {props.isAuthenticated && props.isAdmin && <AdminMenu toggle={setMenuOpen} showSwagger={props.isSwaggerEnabled} />}
            {/* <NavItem>
              <NavLink className="text-light"   href="/home">
                Anasayfa
              </NavLink>
            </NavItem> */}
            {/* <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} /> */}
           

            <AccountMenu toggle={setMenuOpen} name={props.isAuthenticated?props.firstName +" "+ props.lastName:""} isAuthenticated={props.isAuthenticated} />
          </Nav>
        </Collapse>
        </div>
      </Navbar>

    </div>
  );
};

export default Header;
