import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IAppointment } from 'app/shared/model/appointment.model';
import { getEntities as getAppointments } from 'app/entities/appointment/appointment.reducer';
import { IRoom } from 'app/shared/model/room.model';
import { getEntities as getRooms } from 'app/entities/room/room.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { getEntities as getPatients } from 'app/entities/patient/patient.reducer';
import { getEntity, updateEntity, createEntity, reset } from './in-patient.reducer';
import { IInPatient } from 'app/shared/model/in-patient.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface IInPatientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const InPatientUpdate = (props: IInPatientUpdateProps) => {
  const [appointmentId, setAppointmentId] = useState('0');
  const [roomId, setRoomId] = useState('0');
  const [patientId, setPatientId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { inPatientEntity, appointments, rooms, patients, loading, updating } = props;

  const handleClose = () => {
    if(props.isPhysician || props.isStaff)
    props.history.push('/ps-in-patient');
    else
    props.history.push('/in-patient');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getAppointments();
    props.getRooms();
    props.getPatients();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);

    if (errors.length === 0) {
      const entity = {
        ...inPatientEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hospitalmsappfrontendApp.inPatient.home.createOrEditLabel">
            <Translate contentKey="hospitalmsappfrontendApp.inPatient.home.createOrEditLabel">Create or edit a InPatient</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : inPatientEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="in-patient-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="in-patient-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="startDateLabel" for="in-patient-startDate">
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.startDate">Start Date</Translate>
                </Label>
                <AvInput
                  id="in-patient-startDate"
                  type="datetime-local"
                  className="form-control"
                  name="startDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.inPatientEntity.startDate)}
                  readOnly={props.isPhysician?true:false}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="endDateLabel" for="in-patient-endDate">
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.endDate">End Date</Translate>
                </Label>
                <AvInput
                  id="in-patient-endDate"
                  type="datetime-local"
                  className="form-control"
                  name="endDate"
                  readOnly={props.isPhysician?true:false}
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.inPatientEntity.endDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="in-patient-description">
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.description">Description</Translate>
                </Label>
                <AvField id="in-patient-description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="createdDateLabel" for="in-patient-createdDate">
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.createdDate">Created Date</Translate>
                </Label>
                <AvInput
                  id="in-patient-createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.inPatientEntity.createdDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label for="in-patient-appointment">
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.appointment">Appointment</Translate>
                </Label>
                <AvInput disabled={props.isPhysician||props.isStaff?true:false} id="in-patient-appointment" type="select" className="form-control" name="appointment.id">
                  <option value="" key="0" />
                  {appointments
                    ? appointments.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>

              <Label id="statusLabel" for="in-patient-status">
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.status">Status</Translate>
                </Label>
              <AvInput
                  id="in-patient-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && props.inPatientEntity.status) || 'UNAPPROVED'}
                >
                  <option value="UNAPPROVED">{translate('hospitalmsappfrontendApp.inPatientStatus.UNAPPROVED')}</option>
                  <option value="STAYING">{translate('hospitalmsappfrontendApp.inPatientStatus.STAYING')}</option>
                  <option value="DISCHARGED">{translate('hospitalmsappfrontendApp.inPatientStatus.DISCHARGED')}</option>
                  <option value="CANCELLED">{translate('hospitalmsappfrontendApp.inPatientStatus.CANCELLED')}</option>
                </AvInput>
                </AvGroup>       
              <AvGroup>
                <Label for="in-patient-room">
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.room">Room</Translate>
                </Label>
                <AvInput id="in-patient-room" type="select" className="form-control" name="room.id">
                  <option value="" key="0" />
                  {rooms
                    ? rooms.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.roomNumber+":"+otherEntity.roomType+" "+otherEntity.description}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="in-patient-patient">
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.patient">Patient</Translate>
                </Label>
                <AvInput disabled={true} id="in-patient-patient" type="select" className="form-control" name="patient.id">
                  <option value="" key="0" />
                  {patients
                    ? patients.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.firstName+" "+otherEntity.lastName}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
            {
              props.isPhysician||props.isStaff?
              <Button tag={Link} id="cancel-save" to="/ps-in-patient" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>

              :<Button tag={Link} id="cancel-save" to="/in-patient" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>

            }
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  appointments: storeState.appointment.entities,
  rooms: storeState.room.entities.map(item=>item.status===false?item:{id:item.id,roomNumber:item.roomNumber,roomType:item.roomType,description:"FULL-UNAVAILABLE"}),
  patients: storeState.patient.entities,
  inPatientEntity: storeState.inPatient.entity,
  loading: storeState.inPatient.loading,
  updating: storeState.inPatient.updating,
  updateSuccess: storeState.inPatient.updateSuccess,
  isPhysician: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PHYSICIAN]),
  isStaff: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.STAFF])
});

const mapDispatchToProps = {
  getAppointments,
  getRooms,
  getPatients,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InPatientUpdate);
