import './home.scss';

import React from 'react';

import { connect } from 'react-redux';



import Hero from 'app/tpui/Hero';
import Main from 'app/tpui/Main';




export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { account } = props;

  return (
    <div>    
    {/* <Topbar/> */}
    <Hero/>
    <Main/>
    {/* <Contact/> */}
    {/* <Footer/> */}
    </div>

  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
