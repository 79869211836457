import { Moment } from 'moment';

export interface ICTestItem {
  id?: number;
  name?: string;
  description?: string;
  price?: number;
  defaultValMin?: string;
  defaultValMax?: string;
  createdDate?: string;
}

export const defaultValue: Readonly<ICTestItem> = {};
