import axios from 'axios';
import { translate } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  CREATE_MESSAGE: 'contact/CREATE_MESSAGE',
  RESET: 'contact/RESET',
};

const initialState = {
  loading: false,
  registrationSuccess: false,
  registrationFailure: false,
  errorMessage: null,
};

export type RegisterState = Readonly<typeof initialState>;

// Reducer
export default (state: RegisterState = initialState, action): RegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_MESSAGE):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_MESSAGE):
      return {
        ...initialState,
        registrationFailure: true,
        errorMessage: action.payload.response.data.errorKey,
      };
    case SUCCESS(ACTION_TYPES.CREATE_MESSAGE):
      return {
        ...initialState,
        registrationSuccess: true,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
export const sendMessage = (name, email, subject, message) => ({
  type: ACTION_TYPES.CREATE_MESSAGE,
  payload: axios.post(process.env.REACT_APP_API_URL +'/c-messages/request', { name, email, subject, message }),
  meta: {
    successMessage: translate('hospitalmsappfrontendApp.cMessage.success'),
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
