import * as React from 'react';

export interface IAppProps {
  title?:string;
}

export default function Hero (props: IAppProps) {
  return (
    <div>
    <section id="hero" className="d-flex align-items-center">
    <div className="container">
      <h1>Welcome to {process.env.REACT_APP_COMPANY}</h1>
      <h2>Best of Care, Close to Home</h2>
      <h2>Our patients are at the centre of everything we do</h2>
      <a href="#appointment" className="btn-get-started scrollto">Get Started</a>
    </div>
  </section>        
    </div>
  );
}
