import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from './menu-components';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';

export const PatientMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.mypages-patient')}
    id="entity-menu"
    style={{ maxHeight: '80vh', overflow: 'auto'}}
  >
    <MenuItem toggle={props.toggle}   icon={faCalendarWeek} to="/patient-appointment">
      <Translate contentKey="global.menu.entities.myappointments" />
    </MenuItem>

    <MenuItem toggle={props.toggle}   icon={faCalendarWeek} to="/make-appointment">
      <Translate contentKey="global.menu.entities.makeappointment" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
