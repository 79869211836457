import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CMessage from './c-message';
import CMessageDetail from './c-message-detail';
import CMessageUpdate from './c-message-update';
import CMessageDeleteDialog from './c-message-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CMessageDetail} />
      <ErrorBoundaryRoute path={match.url} component={CMessage} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CMessageDeleteDialog} />
  </>
);

export default Routes;
