import axios from 'axios';
import { ICrudGetAllAction, logInfo, translate } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IUser, defaultValue } from 'app/shared/model/user.model';

export const ACTION_TYPES = {
  FETCH_USER: 'user_search/FETCH_USER',
  RESET: 'user_search/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  user: defaultValue,
};

export type UserSearchState = Readonly<typeof initialState>;

// Reducer
export default (state: UserSearchState = initialState, action): UserSearchState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER): {
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/user';
// Actions
export const searchUser = ssn => {
  const requestUrl = apiUrl + '?ssn=' + ssn;
  return {
    type: ACTION_TYPES.FETCH_USER,
    payload: axios.get<IUser>(requestUrl),
    meta: {
      successMessage: translate('hospitalmsappfrontendApp.physician.userfoundsuccess'),
    },
  };
};

export const resetUser = () => ({
  type: ACTION_TYPES.RESET,
});
