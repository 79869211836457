import axios from 'axios';
import { translate } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IUser, defaultValue } from 'app/shared/model/user.model';
import { ICrudPutAction } from 'react-jhipster';
export const ACTION_TYPES = {
  CREATE_ACCOUNT: 'register/CREATE_ACCOUNT',
  RESET: 'register/RESET',
};

const initialState = {
  loading: false,
  entity:defaultValue,
  registrationSuccess: false,
  registrationFailure: false,
  updateSuccess:false,
  errorMessage: null,
};

export type RegisterState = Readonly<typeof initialState>;

// Reducer
export default (state: RegisterState = initialState, action): RegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...state,
        updateSuccess:false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...state,
        registrationFailure: true,
        updateSuccess:false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...state,
        updateSuccess:true,
        registrationSuccess: true,
        entity:action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
// export const handleRegister = (ssn, firstName, lastName, login, email, password, langKey = 'en') => ({
//   type: ACTION_TYPES.CREATE_ACCOUNT,
//   payload: axios.post('api/register', { ssn, firstName, lastName, login, email, password, langKey }),
//   meta: {
//     successMessage: translate('register.messages.success'),
//     errorMessage: translate('register.messages.error.common'),
//   },
// });

export const handleRegister: ICrudPutAction<IUser> = user => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ACCOUNT,
    payload: axios.post(process.env.REACT_APP_API_URL +'/register', user),
  });
  return result;
};



export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
