import { Moment } from 'moment';
import { ICountry } from 'app/shared/model/country.model';

export interface ICState {
  id?: number;
  name?: string;
  createdDate?: string;
  country?: ICountry;
}

export const defaultValue: Readonly<ICState> = {};
