import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount, logInfo } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { sendTestRequest, getEntities } from './physician-test-item.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { FormGroup, AvForm, AvInput } from 'availity-reactstrap-validation';

export interface IPhysicianTestRequestProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PhysicianTest = (props: IPhysicianTestRequestProps) => {

  const { cTestItemList, match, loading, updating } = props;

  const [selectedTest, setSelectedTest] = useState({});
  const [array, setArray] = useState([]);
  const [appointmentId, setAppointmentId] = useState("");

  useEffect(() => {
    props.getEntities();
    setAppointmentId(props.match.params.id);
  }, []);

  const handleClose = () => {
    props.history.push('/appointment-update/'+props.match.params.id);
  };


  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const onClick = (event) => {
    const name = { id: event.target.name };
    const value = { value: event.target.checked };
    const item = Object.assign(name, value);

    const index = array.findIndex((el) => el.id === event.target.name)
    if (index > -1) {
      array[index] = item;
    } else {
      array.push(item);
    }
    setArray(array);
  }


  const getValue = (item) => {
    if (item.value === true)
      return item.id;
  }

  const saveTest = (event, errors, values) => {
    const testIds = array.map(getValue);

    logInfo(testIds);

   props.sendTestRequest({appointmentId, testIds });


  }


  return (
    <div>
      <h2 id="c-test-item-heading">
        Test Items
      </h2>
      <AvForm onSubmit={saveTest}>
        <div className="table-responsive">
          {cTestItemList && cTestItemList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" >
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand">
                    <Translate contentKey="hospitalmsappfrontendApp.cTestItem.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" >
                    <Translate contentKey="hospitalmsappfrontendApp.cTestItem.description">Description</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" >
                    <Translate contentKey="hospitalmsappfrontendApp.cTestItem.price">Price</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" >
                    <Translate contentKey="hospitalmsappfrontendApp.cTestItem.defaultValMin">Default Val Min</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" >
                    <Translate contentKey="hospitalmsappfrontendApp.cTestItem.defaultValMax">Default Val Max</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>

                {cTestItemList.map((cTestItem, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${cTestItem.id}`} color="link" size="sm">
                        {cTestItem.id}
                      </Button>
                    </td>
                    <td>{cTestItem.name}</td>
                    <td>{cTestItem.description}</td>
                    <td>{cTestItem.price}</td>
                    <td>{cTestItem.defaultValMin}</td>
                    <td>{cTestItem.defaultValMax}</td>

                    <td>
                      {cTestItem.createdDate ? <TextFormat type="date" value={cTestItem.createdDate} format={APP_DATE_FORMAT} /> : null}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">

                      </div>
                    </td>

                    <td className="text-right">
                      <AvInput value={false} type="checkbox" onChange={onClick} name={cTestItem.id} />
                    </td>
                  </tr>
                ))}

              </tbody>
            </Table>

          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="hospitalmsappfrontendApp.cTestItem.home.notFound">No C Test Items found</Translate>
              </div>
            )
          )}

        </div>
        <Row>
          <Col>
            <Button color="primary" id="save-entity" type="submit" disabled={updating}>
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="entity.action.save">Save</Translate>
            </Button>
          </Col>
        </Row>

      </AvForm>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  cTestItemList: storeState.physicianTestITem.entities,
  loading: storeState.physicianTestITem.loading,
  updating: storeState.physicianTestITem.updating,
  updateSuccess:storeState.physicianTestITem.updateSuccess
});

const mapDispatchToProps = {
  getEntities,
  sendTestRequest
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianTest);