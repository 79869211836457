import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Patient from './patient';
import Staff from './staff';
import Physician from './physician';
import Appointment from './appointment';
import CTest from './c-test';
import CTestItem from './c-test-item';
import CTestResult from './c-test-result';
import Bill from './bill';
import Room from './room';
import InPatient from './in-patient';
import Country from './country';
import CState from './c-state';
import ContactMessage  from './c-message';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div style={{padding:200}}>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}patient`} component={Patient} />
      <ErrorBoundaryRoute path={`${match.url}staff`} component={Staff} />
      <ErrorBoundaryRoute path={`${match.url}physician`} component={Physician} />
      <ErrorBoundaryRoute path={`${match.url}appointment`} component={Appointment} />
      <ErrorBoundaryRoute path={`${match.url}c-test`} component={CTest} />
      <ErrorBoundaryRoute path={`${match.url}c-test-item`} component={CTestItem} />
      <ErrorBoundaryRoute path={`${match.url}c-test-result`} component={CTestResult} />
      <ErrorBoundaryRoute path={`${match.url}bill`} component={Bill} />
      <ErrorBoundaryRoute path={`${match.url}room`} component={Room} />
      <ErrorBoundaryRoute path={`${match.url}country`} component={Country} />
      <ErrorBoundaryRoute path={`${match.url}c-state`} component={CState} />
      <ErrorBoundaryRoute path={`${match.url}c-message`} component={ContactMessage} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
