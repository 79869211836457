import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './physician-patient.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IPatientProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const PhysicianPatient = (props: IPatientProps) => {

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
    const { patientList, match, loading, totalItems } = props;
    return (
      <div>
        <h2 id="patient-heading">
          <Translate contentKey="hospitalmsappfrontendApp.patient.home.title">Patients</Translate>
        </h2>
        <div className="table-responsive">
          {patientList && patientList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand">
                    <Translate contentKey="hospitalmsappfrontendApp.patient.ssn">SSN</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('firstName')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.firstName">FirstName</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('lastName')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.lastName">LastName</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('birthDate')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.birthDate">Birth Date</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('phone')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.phone">Phone</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
  
                  <th className="hand" onClick={sort('phone')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.email">Email</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
  
                  <th className="hand" onClick={sort('gender')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.gender">Gender</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('bloodGroup')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.bloodGroup">Blood Group</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('adress')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.adress">Adress</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('description')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.description">Description</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('createdDate')}>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.createdDate">Created Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.country">Country</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="hospitalmsappfrontendApp.patient.cstate">Cstate</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {patientList.map((patient, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button color="link" size="sm">
                        {patient.id}
                      </Button>
                    </td>
                    <td>{patient&&patient.user?patient.user.ssn:null}</td>
                    <td>{patient.firstName}</td>
                    <td>{patient.lastName}</td>
                    <td>{patient.birthDate ? <TextFormat type="date" value={patient.birthDate} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{patient.phone}</td>
                    <td>{patient.email}</td>
                    <td>
                      <Translate contentKey={`hospitalmsappfrontendApp.Gender.${patient.gender}`} />
                    </td>
                    <td>
                      <Translate contentKey={`hospitalmsappfrontendApp.BloodGroup.${patient.bloodGroup}`} />
                    </td>
                    <td>{patient.adress}</td>
                    <td>{patient.description}</td>
                    <td>{patient.createdDate ? <TextFormat type="date" value={patient.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{patient.country ? patient.country.name: ''}</td>
                    <td>{patient.cstate ? patient.cstate.name : ''}</td>
                    <td className="text-right">
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="hospitalmsappfrontendApp.patient.home.notFound">No Patients found</Translate>
              </div>
            )
          )}
        </div>
        {props.totalItems ? (
          <div className={patientList && patientList.length > 0 ? '' : 'd-none'}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={props.totalItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };
  
  const mapStateToProps = ({ physicianPatient,authentication }: IRootState) => ({
    patientList: physicianPatient.entities,
    loading: physicianPatient.loading,
    totalItems: physicianPatient.totalItems,
    isPhysician:hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PHYSICIAN]),
  });
  
  const mapDispatchToProps = {
    getEntities,
  };
  
  type StateProps = ReturnType<typeof mapStateToProps>;
  type DispatchProps = typeof mapDispatchToProps;
  
  export default connect(mapStateToProps, mapDispatchToProps)(PhysicianPatient);