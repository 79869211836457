import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAppointment, defaultValue } from 'app/shared/model/appointment.model';

export const ACTION_TYPES = {
  FETCH_PHYSICIANAPPOINTMENT_LIST: 'ps-appointment/FETCH_PHYSICIANAPPOINTMENT_LIST',
  FETCH_PHYSICIANAPPOINTMENT: 'ps-appointment/FETCH_PHYSICIANAPPOINTMENT',
  FETCH_PHYSICIAN_UPDATEAPPOINTMENT: 'ps-appointment/FETCH_PHYSICIAN_UPDATEAPPOINTMENT',
  RESET: 'ps-appointment/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAppointment>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type AppointmentPhysicianState = Readonly<typeof initialState>;

// Reducer

export default (state: AppointmentPhysicianState = initialState, action): AppointmentPhysicianState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.FETCH_PHYSICIAN_UPDATEAPPOINTMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PHYSICIAN_UPDATEAPPOINTMENT):
    case FAILURE(ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PHYSICIAN_UPDATEAPPOINTMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/appointments';

export const getEntity: ICrudGetAction<IAppointment> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT,
    payload: axios.get<IAppointment>(requestUrl),
  };
};

export const getAppointmentsByPatientId = patientId => {
  const requestUrl = process.env.REACT_APP_API_URL + '/appointments/patient/' + patientId;
  return {
    type: ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT_LIST,
    payload: axios.get(requestUrl),
  };
};

export const getAppointmentsByUserId = (fromDate, toDate, userId) => {
  let requestUrl = process.env.REACT_APP_API_URL + '/appointments/bydateandId?';
  if (fromDate) {
    requestUrl += `&fromDate=${fromDate}`;
  }
  if (toDate) {
    requestUrl += `&toDate=${toDate}`;
  }

  if (userId) {
    requestUrl += `&userId=${userId}`;
  }

  return {
    type: ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT_LIST,
    payload: axios.get(requestUrl),
  };
};

export const getAppointmentsByDate = (fromDate, toDate) => {
  let requestUrl = process.env.REACT_APP_API_URL + '/appointments/staff/bydate?';
  if (fromDate) {
    requestUrl += `&fromDate=${fromDate}`;
  }
  if (toDate) {
    requestUrl += `&toDate=${toDate}`;
  }

  return {
    type: ACTION_TYPES.FETCH_PHYSICIANAPPOINTMENT_LIST,
    payload: axios.get(requestUrl),
  };
};

export const updateEntity: ICrudPutAction<IAppointment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_PHYSICIAN_UPDATEAPPOINTMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
