import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ITestResultDTO } from 'app/shared/model/test-result-dto.model';
import { defaultValue } from 'app/shared/model/c-test-result.model';
import { ICTestResult } from 'app/shared/model/c-test-result.model';
import { cleanEntity } from 'app/shared/util/entity-utils';

export const ACTION_TYPES = {
  FETCH_PATIENT_CTESTRESULT_LIST: 'ps-cTestResult/FETCH_PATIENT_CTESTRESULT_LIST',
  PS_UPDATE_CTESTRESULT: 'ps-cTestResult/PS_UPDATE_CTESTRESULT',
  PS_FETCH_CTESTRESULT: 'ps-cTestResult/PS_FETCH_CTESTRESULT',
  RESET: 'ps-cTestResult/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITestResultDTO>,
  updating: false,
  entity: defaultValue,
  updateSuccess: false,
};

export type PatientTestResultState = Readonly<typeof initialState>;

// Reducer

export default (state: PatientTestResultState = initialState, action): PatientTestResultState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENT_CTESTRESULT_LIST):
    case REQUEST(ACTION_TYPES.PS_FETCH_CTESTRESULT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.PS_UPDATE_CTESTRESULT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENT_CTESTRESULT_LIST):
    case FAILURE(ACTION_TYPES.PS_UPDATE_CTESTRESULT):
    case FAILURE(ACTION_TYPES.PS_FETCH_CTESTRESULT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT_CTESTRESULT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.PS_FETCH_CTESTRESULT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.PS_UPDATE_CTESTRESULT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/c-test-results/c-test';

// Actions

export const getEntities: ICrudGetAllAction<ITestResultDTO> = id => ({
  type: ACTION_TYPES.FETCH_PATIENT_CTESTRESULT_LIST,
  payload: axios.get<ITestResultDTO>(apiUrl + '/' + id),
});

export const updateEntity: ICrudPutAction<ICTestResult> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PS_UPDATE_CTESTRESULT,
    payload: axios.put(process.env.REACT_APP_API_URL + '/c-test-results', cleanEntity(entity)),
  });
  return result;
};

export const getEntity: ICrudGetAction<ICTestResult> = id => {
  const requestUrl = process.env.REACT_APP_API_URL + '/c-test-results' + `/${id}`;
  return {
    type: ACTION_TYPES.PS_FETCH_CTESTRESULT,
    payload: axios.get<ICTestResult>(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
