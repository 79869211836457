import React, { useState, useEffect } from 'react';
import { logInfo, Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Input, Row, Col, Alert, Button } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { sendMessage, reset } from './contact.form.reducer';
import { toast } from 'react-toastify';

export interface IRegisterProps extends StateProps, DispatchProps { }

export const ContactForm = (props: IRegisterProps) => {

  const today = (): string => {
    // Today + 1 day - needed if the current day must be included
    const day: Date = new Date();
    day.setDate(day.getDate() + 1);
    const toDate = new Date(day.getFullYear(), day.getMonth(), day.getDate());
    return toDate.toISOString().slice(0, 10);
  };

  const [appoDate, setAppoDate] = useState(today());
  const [appoDateError, setAppoDateError] = useState(false);

  useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  const handleValidSubmit = (event, values) => {
    props.sendMessage(values.name, values.email, values.subject, values.message);
    event.preventDefault();
  };

  const onChangeAppoDate = evt => {
    const date = new Date(evt.target.value);
    const isBefore = date <= new Date();

    if (isBefore) {
      setAppoDate(evt.target.value);
      setAppoDateError(true);

    } else {
      setAppoDate(evt.target.value);
      setAppoDateError(false);
    }

  };

  return (
    <AvForm id="register-form" onValidSubmit={handleValidSubmit}>
      <Row className="justify-content-center">
        <Col md="6">
          <AvField
            name="name"
            label={translate('hospitalmsappfrontendApp.cMessage.name')}
            placeholder={translate('hospitalmsappfrontendApp.cMessage.name')}
            validate={{
              required: { value: true, errorMessage: translate('hospitalmsappfrontendApp.cMessage.validate.name.required') }
            }}
          />
        </Col>
        <Col md="6">
          <AvField
            name="email"
            label={translate('hospitalmsappfrontendApp.cMessage.email')}
            placeholder={translate('hospitalmsappfrontendApp.cMessage.email')}
            type="email"
            validate={{
              required: { value: true, errorMessage: translate('global.messages.validate.email.required') },
              minLength: { value: 1, errorMessage: translate('global.messages.validate.email.minlength') },
              maxLength: { value: 255, errorMessage: translate('global.messages.validate.email.maxlength') },
            }}
          />
        </Col>
      </Row>
      <Row>

        <Col md="12">
          <AvField
            name="subject"
            label={translate('hospitalmsappfrontendApp.cMessage.subject')}
            placeholder={translate('hospitalmsappfrontendApp.cMessage.subject')}
            validate={{
              required: { value: true, errorMessage: translate('entity.validation.required') },
              maxLength: { value: 150, errorMessage: translate('entity.validation.maxlength', { max: 150 }) },
            }}
          />
        </Col>
      </Row>

      <Row>

        <Col md="12">
          <AvField
            name="message"
            type="textarea"
            rows={8}
            label={translate('hospitalmsappfrontendApp.cMessage.message')}
            placeholder={translate('hospitalmsappfrontendApp.cMessage.message')}
            validate={{
              maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) },
            }}
          />
        </Col>
      </Row>

      <Row align="center" style={{ margin: 20 }}>
        <Col md="12" >
          <Button id="register-submit" color="primary" type="submit" style={{ paddingLeft: 50, paddingRight: 50 }}>
            <Translate contentKey="hospitalmsappfrontendApp.cMessage.send">Send</Translate>
          </Button>
        </Col>
      </Row>
    </AvForm>
  );
};

const mapStateToProps = ({ locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
});

const mapDispatchToProps = { sendMessage, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
