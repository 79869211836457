import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './c-test-result.reducer';
import { ICTestResult } from 'app/shared/model/c-test-result.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICTestResultDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CTestResultDetail = (props: ICTestResultDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { cTestResultEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="hospitalmsappfrontendApp.cTestResult.detail.title">CTestResult</Translate> [<b>{cTestResultEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="date">
              <Translate contentKey="hospitalmsappfrontendApp.cTestResult.date">Date</Translate>
            </span>
          </dt>
          <dd>{cTestResultEntity.date ? <TextFormat value={cTestResultEntity.date} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="result">
              <Translate contentKey="hospitalmsappfrontendApp.cTestResult.result">Result</Translate>
            </span>
          </dt>
          <dd>{cTestResultEntity.result}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hospitalmsappfrontendApp.cTestResult.description">Description</Translate>
            </span>
          </dt>
          <dd>{cTestResultEntity.description}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="hospitalmsappfrontendApp.cTestResult.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {cTestResultEntity.createdDate ? (
              <TextFormat value={cTestResultEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.cTestResult.cTestItem">C Test Item</Translate>
          </dt>
          <dd>{cTestResultEntity.ctestItem ? cTestResultEntity.ctestItem.name : ''}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.cTestResult.cTest">C Test</Translate>
          </dt>
          <dd>{cTestResultEntity.ctest ? cTestResultEntity.ctest.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/c-test-result" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/c-test-result/${cTestResultEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ cTestResult }: IRootState) => ({
  cTestResultEntity: cTestResult.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CTestResultDetail);
