import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { setFileData, openFile, byteSize, Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, logInfo } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUser } from 'app/shared/model/user.model';
import { getUsers,getUsersNoPatient } from 'app/modules/administration/user-management/user-management.reducer';
import { searchUser, resetUser } from 'app/entities/physician/user-search-reducer';
import { ICountry } from 'app/shared/model/country.model';
import { getEntities as getCountries } from 'app/entities/country/country.reducer';
import { ICState } from 'app/shared/model/c-state.model';
import { getEntities as getCStates, getEntitiesByCountryId } from 'app/entities/c-state/c-state.reducer';
import { getEntity, updateEntity, createEntity,setBlob, reset } from './physician.reducer';
import { IPhysician } from 'app/shared/model/physician.model';
import {now,convertDate,convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IPhysicianUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const PhysicianUpdate = (props: IPhysicianUpdateProps) => {
  const [userId, setUserId] = useState('0');
  const [countryId, setCountryId] = useState('0');
  const [cstateId, setCstateId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const [ssn, setSSN] = useState('');
  const [isCheck,setCheck]=useState(false);
  const [clicked, setClicked] = useState(false);


  const [selectedCountry, setSelectedCountry] = useState('0');

  const { foundUser, physicianEntity, users, countries, cStates, loading, updating } = props;

  const { image, imageContentType } = physicianEntity;
  const handleClose = () => {
    props.history.push('/physician' + props.location.search);
    props.resetUser();
    setCheck(false);
  };

  const onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => props.setBlob(name, data, contentType), isAnImage);
  };

  const clearBlob = name => () => {
    props.setBlob(name, undefined, undefined);
  };

  useEffect(() => {
    if (isNew) {
      setCheck(false);
      props.reset();
      props.resetUser();
    } else {
      // props.resetUser();
      setCheck(false);
      props.getEntity(props.match.params.id);
    }

    props.getUsersNoPatient();
    props.getCountries();
    props.getCStates();
  }, []);




  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (selectedCountry) {
      props.getEntitiesByCountryId(Number(selectedCountry))
    }
  }, [selectedCountry]);


  const saveEntity = (event, errors, values) => {
    values.birthDate = convertDateTimeToServer(values.birthDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);
    // values.user.id = user.id;
    // values.user.login = user.login;
    if(isCheck){
      values.user=foundUser;
    }

    if (errors.length === 0) {
      const entity = {
        ...physicianEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const clickSearch = (e) => {
    e.preventDefault();
    props.searchUser(ssn);
  }

  const changeSearchSSN=(event) => {
    setSSN(event.target.value);
  }

  const changeCountry = (event) => {
    setSelectedCountry(event.target.value);
  }

  const changeCheckBox=(event)=>{
    logInfo("checked:"+event.target.checked);
    setCheck(event.target.checked);
 
  }

  // const getValue = () => {
  //   const ok = user && user.id ? user.login : "";
  //   return ok;

  // }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hospitalmsappfrontendApp.physician.home.createOrEditLabel">
            <Translate contentKey="hospitalmsappfrontendApp.physician.home.createOrEditLabel">Create or edit a Physician</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : physicianEntity} onSubmit={saveEntity}>

              <Row style={{margin:"0px"}}>
              <Col sm="2">
                <AvGroup>
                 
                  <AvInput type="checkbox" onChange={changeCheckBox} name="useSSNSearch" />
                     <Label check for="checkbox"> Use Search</Label>
                  </AvGroup>
                </Col>

                <Col sm="10">
                <AvGroup>
                  <Label>
                    SSN
                  </Label>
                  <AvField type="text"
                   placeHolder="000-00-0000"
                    name="searchSSN" onChange={changeSearchSSN} />
                  <Button type="" onClick={(event) => clickSearch(event)}>Search User</Button>
                  </AvGroup>
                </Col>
              </Row>
              


              {!isNew ? (
                <AvGroup>
                  <Label for="physician-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="physician-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="firstNameLabel" for="physician-firstName">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.firstName">firstName</Translate>
                </Label>
                <AvField
                  id="physician-firstName"
                  type="text"
                  name="firstName"
                  value={isCheck&&foundUser?foundUser.firstName:null}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="physician-lastName">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.lastName">LastName</Translate>
                </Label>
                <AvField
                  id="physician-lastName"
                  type="text"
                  name="lastName"
                  value={isCheck&&foundUser?foundUser.lastName:null}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              {/* <AvGroup>
                <Label id="birthDateLabel" for="physician-birthDate">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.birthDate">Birth Date</Translate>
                </Label>
                <AvInput
                  id="physician-birthDate"
                  type="datetime-local"
                  className="form-control"
                  name="birthDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.physicianEntity.birthDate)}
                />
              </AvGroup> */}

              
              <AvGroup>
                <AvField
              id="physician1-birthDate"
              type="date"
              className="form-control"
              name="birthDate"
              placeholder={'YYYY-MM-DD'}
              validate={{
                dateRange: {start: {value: -200, units: 'years',} ,end: {value: -20, units: 'years'}}
            }}
             
              value={isNew ? now() : convertDate(props.physicianEntity.birthDate)}
                />
              </AvGroup>

              <AvGroup>
                <Label id="phoneLabel" for="physician-phone">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.phone">Phone</Translate>
                </Label>
                <AvField
                  id="physician-phone"
                  type="text"
                  name="phone"
                  validate={{
                    required: { value: true, errorMessage: translate('register.messages.validate.phone.required') },
                    pattern: {
                      value: '/^(1\\s|1|)?((\\(\\d{3}\\))|\\d{3})(\\-|\\s)?(\\d{3})(\\-|\\s)?(\\d{4})$/',
                      errorMessage: "Phone number is invalid",
                      maxLength: { value: 12 }
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="adressLabel" for="physician-adress">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.adress">Adress</Translate>
                </Label>
                <AvField id="physician-adress" type="text" name="adress" />
              </AvGroup>
              <AvGroup>
                <Label id="genderLabel" for="physician-gender">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.gender">Gender</Translate>
                </Label>
                <AvInput
                  id="physician-gender"
                  type="select"
                  className="form-control"
                  name="gender"
                  value={(!isNew && physicianEntity.gender) || 'MALE'}
                >
                  <option value="MALE">{translate('hospitalmsappfrontendApp.Gender.MALE')}</option>
                  <option value="FEMALE">{translate('hospitalmsappfrontendApp.Gender.FEMALE')}</option>
                  <option value="OTHER">{translate('hospitalmsappfrontendApp.Gender.OTHER')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="specialityLabel" for="physician-speciality">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.speciality">Speciality</Translate>
                </Label>
                <AvInput
                  id="physician-speciality"
                  type="select"
                  className="form-control"
                  name="speciality"
                  value={(!isNew && physicianEntity.speciality) || 'ALLERGY_IMMUNOLOGY'}
                >
                  <option value="ALLERGY_IMMUNOLOGY">{translate('hospitalmsappfrontendApp.Speciality.ALLERGY_IMMUNOLOGY')}</option>
                  <option value="ANESTHESIOLOGY">{translate('hospitalmsappfrontendApp.Speciality.ANESTHESIOLOGY')}</option>
                  <option value="DERMATOLOGY">{translate('hospitalmsappfrontendApp.Speciality.DERMATOLOGY')}</option>
                  <option value="DIAGNOSTIC_RADIOLOGY">{translate('hospitalmsappfrontendApp.Speciality.DIAGNOSTIC_RADIOLOGY')}</option>
                  <option value="EMERGENCY_MEDICINE">{translate('hospitalmsappfrontendApp.Speciality.EMERGENCY_MEDICINE')}</option>
                  <option value="FAMILY_MEDICINE">{translate('hospitalmsappfrontendApp.Speciality.FAMILY_MEDICINE')}</option>
                  <option value="INTERNAL_MEDICINE">{translate('hospitalmsappfrontendApp.Speciality.INTERNAL_MEDICINE')}</option>
                  <option value="MEDICAL_GENETICS">{translate('hospitalmsappfrontendApp.Speciality.MEDICAL_GENETICS')}</option>

                  <option value="NEUROLOGY">{translate('hospitalmsappfrontendApp.Speciality.NEUROLOGY')}</option>
                  <option value="NUCLEAR_MEDICINE">{translate('hospitalmsappfrontendApp.Speciality.NUCLEAR_MEDICINE')}</option>
                  <option value="OBSTETRICS_GYNECOLOGY">{translate('hospitalmsappfrontendApp.Speciality.OBSTETRICS_GYNECOLOGY')}</option>
                  <option value="DIAGNOSTIC_RADIOLOGY">{translate('hospitalmsappfrontendApp.Speciality.DIAGNOSTIC_RADIOLOGY')}</option>
                  <option value="OPHTHALMOLOGY">{translate('hospitalmsappfrontendApp.Speciality.OPHTHALMOLOGY')}</option>
                  <option value="PATHOLOGY">{translate('hospitalmsappfrontendApp.Speciality.PATHOLOGY')}</option>
                  <option value="PEDIATRICS">{translate('hospitalmsappfrontendApp.Speciality.PEDIATRICS')}</option>
                  <option value="PHYSICALMEDICINE_REHABILITATION">{translate('hospitalmsappfrontendApp.Speciality.PHYSICALMEDICINE_REHABILITATION')}</option>


                  <option value="PREVENTIVE_MEDICINE">{translate('hospitalmsappfrontendApp.Speciality.PREVENTIVE_MEDICINE')}</option>
                  <option value="PSYCHIATRY">{translate('hospitalmsappfrontendApp.Speciality.PSYCHIATRY')}</option>
                  <option value="RADIATION_ONCOLOGY">{translate('hospitalmsappfrontendApp.Speciality.RADIATION_ONCOLOGY')}</option>
                  <option value="SURGERY">{translate('hospitalmsappfrontendApp.Speciality.SURGERY')}</option>
                  <option value="UROLOGY">{translate('hospitalmsappfrontendApp.Speciality.UROLOGY')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="bloodGroupLabel" for="physician-bloodGroup">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.bloodGroup">Blood Group</Translate>
                </Label>
                <AvInput
                  id="physician-bloodGroup"
                  type="select"
                  className="form-control"
                  name="bloodGroup"
                  value={(!isNew && physicianEntity.bloodGroup) || 'Apositive'}
                >
                  <option value="Apositive">{translate('hospitalmsappfrontendApp.BloodGroup.Apositive')}</option>
                  <option value="Anegative">{translate('hospitalmsappfrontendApp.BloodGroup.Anegative')}</option>
                  <option value="Bpositive">{translate('hospitalmsappfrontendApp.BloodGroup.Bpositive')}</option>
                  <option value="Bnegative">{translate('hospitalmsappfrontendApp.BloodGroup.Bnegative')}</option>
                  <option value="Opositive">{translate('hospitalmsappfrontendApp.BloodGroup.Opositive')}</option>
                  <option value="Onegative">{translate('hospitalmsappfrontendApp.BloodGroup.Onegative')}</option>
                  <option value="ABpositive">{translate('hospitalmsappfrontendApp.BloodGroup.ABpositive')}</option>
                  <option value="ABnegative">{translate('hospitalmsappfrontendApp.BloodGroup.ABnegative')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="physician-description">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.description">Description</Translate>
                </Label>
                <AvField id="physician-description" type="text" name="description" />
              </AvGroup>


              <AvGroup>
                  <Label id="imageLabel" for="image">
                    <Translate contentKey="hospitalmsappfrontendApp.physician.image">Image</Translate>
                  </Label>
                  <br />
                  {image ? (
                    <div>
                      {imageContentType ? (
                        <a onClick={openFile(imageContentType, image)}>
                          <img src={`data:${imageContentType};base64,${image}`} style={{ maxHeight: '100px' }} />
                        </a>
                      ) : null}
                      <br />
                      <Row>
                        <Col md="11">
                          <span>
                            {imageContentType}, {byteSize(image)}
                          </span>
                        </Col>
                        <Col md="1">
                          <Button color="danger" onClick={clearBlob('image')}>
                            <FontAwesomeIcon icon="times-circle" />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  <input id="file_image" type="file" onChange={onBlobChange(true, 'image')} accept="image/*" />
                  <AvInput type="hidden" name="image" value={image} />
                </AvGroup>   


              <AvGroup>
                <Label id="createdDateLabel" for="physician-createdDate">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.createdDate">Created Date</Translate>
                </Label>
                <Label> :{isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.physicianEntity.createdDate)}</Label>
              </AvGroup>


              <AvGroup>
                <Label id="examFeeLabel" for="physician-examFee">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.examfee">Exam Fee</Translate>
                </Label>
                <AvField
                  id="physician-examFee"
                  type="text"
                  name="examFee"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
              </AvGroup>     


            
              <AvGroup>
                <Label for="physician-user">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.user">User</Translate>
                </Label>
                <AvInput
                  id="physician-user"
                  type="select"
                  className="form-control"
                  name="user.id"
                  value={isCheck&&foundUser&&foundUser.id?foundUser.id:isNew ? users[0] && users[0].id : physicianEntity.user?.id}
                  key={isCheck&&foundUser&&foundUser.id?foundUser.id:isNew ? users[0] && users[0].id : physicianEntity.user?.id}
                  required
                >
                  {users
                    ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login+":"+otherEntity.ssn}
                      </option>
                    ))
                    : null}
                </AvInput>
                  </AvGroup>
              
              <AvGroup>
                <Label for="physician-country">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.country">Country</Translate>
                </Label>
                <AvInput id="physician-country" onChange={changeCountry} type="select" className="form-control" name="country.id">
                  <option value="" key="0" />
                  {countries
                    ? countries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="physician-cstate">
                  <Translate contentKey="hospitalmsappfrontendApp.physician.cstate">Cstate</Translate>
                </Label>
                <AvInput id="physician-cstate" type="select" className="form-control" name="cstate.id">
                  <option value="" key="0" />
                  {cStates
                    ? cStates.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/physician" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  foundUser: storeState.userSearchState.user,
  users: storeState.userManagement.users,
  countries: storeState.country.entities,
  cStates: storeState.cState.entities,
  physicianEntity: storeState.physician.entity,
  loading: storeState.physician.loading,
  updating: storeState.physician.updating,
  updateSuccess: storeState.physician.updateSuccess,
});

const mapDispatchToProps = {
  getEntitiesByCountryId,
  getUsersNoPatient,
  searchUser,
  resetUser,
  getCountries,
  getCStates,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setBlob,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianUpdate);
