import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import patient, {
  PatientState
} from 'app/entities/patient/patient.reducer';
// prettier-ignore
import staff, {
  StaffState
} from 'app/entities/staff/staff.reducer';
// prettier-ignore
import physician, {
  PhysicianState
} from 'app/entities/physician/physician.reducer';
// prettier-ignore
import appointment, {
  AppointmentState
} from 'app/entities/appointment/appointment.reducer';
// prettier-ignore

// prettier-ignore
import cTest, {
  CTestState
} from 'app/entities/c-test/c-test.reducer';
// prettier-ignore
import cTestItem, {
  CTestItemState
} from 'app/entities/c-test-item/c-test-item.reducer';
// prettier-ignore
import cTestResult, {
  CTestResultState
} from 'app/entities/c-test-result/c-test-result.reducer';
// prettier-ignore
import bill, {
  BillState
} from 'app/entities/bill/bill.reducer';
// prettier-ignore
import room, {
  RoomState
} from 'app/entities/room/room.reducer';
// prettier-ignore
import inPatient, {
  InPatientState
} from 'app/entities/in-patient/in-patient.reducer';
// prettier-ignore
import country, {
  CountryState
} from 'app/entities/country/country.reducer';
// prettier-ignore
import cState, {
  CStateState
} from 'app/entities/c-state/c-state.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

import userSearchState, { UserSearchState } from 'app/entities/physician/user-search-reducer';

import appointmentPhysician, { AppointmentPhysicianState } from 'app/entities/physicianappointment/physician-appointment.reducer';

import physicianTestITem, { PhysicianTestItemState } from 'app/entities/physicianappointment/physician-test-item.reducer';

import appointmentTests, { AppointmentTestsState } from 'app/entities/appointmenttests/appointment-tests.reducer';

import patientTestResult, { PatientTestResultState } from 'app/entities/patienttestresults/patient-test-result.reducer';

import physicianPatient, { PhysicianPatientState } from 'app/entities/physicianpatient/physician-patient.reducer';
import paymentInvoice, { PaymentInvoiceState } from 'app/entities/paymentinvoice/payment-invoice.reducer';
import invoice, { InvoiceState } from 'app/entities/paymentinvoice/invoice.reducer';

import cMessage, { CMessageState } from 'app/entities/c-message/c-message.reducer';

import patientAppointment, { PatientAppointmentState } from 'app/entities/patientappointment/patient-appointment.reducer';

import appoRequest, { AppoRequestState } from '../../tpui/apporequest/appo.request.reducer';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly patient: PatientState;
  readonly staff: StaffState;
  readonly physician: PhysicianState;
  readonly appointment: AppointmentState;
  readonly cTest: CTestState;
  readonly cTestItem: CTestItemState;
  readonly cTestResult: CTestResultState;
  readonly bill: BillState;
  readonly room: RoomState;
  readonly inPatient: InPatientState;
  readonly country: CountryState;
  readonly cState: CStateState;
  readonly userSearchState: UserSearchState;
  readonly appointmentPhysician: AppointmentPhysicianState;
  readonly physicianTestITem: PhysicianTestItemState;
  readonly appointmentTests: AppointmentTestsState;
  readonly patientTestResult: PatientTestResultState;
  readonly physicianPatient: PhysicianPatientState;
  readonly paymentInvoice: PaymentInvoiceState;
  readonly invoice: InvoiceState;
  readonly cMessage: CMessageState;
  readonly patientAppointment: PatientAppointmentState;
  readonly appoRequest: AppoRequestState;

  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  patient,
  staff,
  physician,
  appointment,
  cTest,
  cTestItem,
  cTestResult,
  bill,
  room,
  inPatient,
  country,
  cState,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  userSearchState,
  appointmentPhysician,
  physicianTestITem,
  appointmentTests,
  patientTestResult,
  physicianPatient,
  paymentInvoice,
  invoice,
  cMessage,
  patientAppointment,
  appoRequest,
});

export default rootReducer;
