import { Moment } from 'moment';
import { ICTest } from 'app/shared/model/c-test.model';
import { IInPatient } from 'app/shared/model/in-patient.model';
import { IBill } from 'app/shared/model/bill.model';
import { IPhysician } from 'app/shared/model/physician.model';
import { IPatient } from 'app/shared/model/patient.model';
import { AppointmentStatus } from 'app/shared/model/enumerations/appointment-status.model';

export interface IAppointment {
  id?: number;
  startDate?: string;
  endDate?: string;
  status?: AppointmentStatus;
  anamnesis?: string;
  treatment?: string;
  diagnosis?: string;
  prescription?: string;
  description?: string;
  createdDate?: string;
  cTests?: ICTest[];
  inPatient?: IInPatient;
  bill?: IBill;
  physician?: IPhysician;
  patient?: IPatient;
}

export const defaultValue: Readonly<IAppointment> = {};
