import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IInPatient } from 'app/shared/model/in-patient.model';
import { getEntities as getInPatients } from 'app/entities/in-patient/in-patient.reducer';
import { IBill } from 'app/shared/model/bill.model';
import { getEntities as getBills } from 'app/entities/bill/bill.reducer';
import { IPhysician } from 'app/shared/model/physician.model';
import { getEntities as getPhysicians } from 'app/entities/physician/physician.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { getEntities as getPatients } from 'app/entities/patient/patient.reducer';
import { getEntity, updateEntity, createEntity, reset } from './appointment.reducer';
import { IAppointment } from 'app/shared/model/appointment.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export interface IAppointmentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const AppointmentUpdate = (props: IAppointmentUpdateProps) => {
  const [inPatientId, setInPatientId] = useState('0');
  const [billId, setBillId] = useState('0');
  const [physicianId, setPhysicianId] = useState('0');
  const [patientId, setPatientId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { isPhysician, appointmentEntity, inPatients, bills, physicians, patients, loading, updating } = props;

  const handleClose = () => {
      props.history.push('/appointment' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getInPatients();
    props.getBills();
    props.getPhysicians();
    props.getPatients();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);

    if (errors.length === 0) {
      const entity = {
        ...appointmentEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };


  return (
    <div>
      <Row style={{marginTop:"50px"}} className="justify-content-center">
        <Col md="8">
          <h2  id="hospitalmsappfrontendApp.appointment.home.createOrEditLabel">
            <Translate contentKey="hospitalmsappfrontendApp.appointment.home.createOrEditLabel">Create or edit a Appointment</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : appointmentEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="appointment-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="appointment-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="startDateLabel" for="appointment-startDate">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.startDate">Start Date</Translate>
                </Label>
                <AvInput
                  id="appointment-startDate"
                  type="datetime-local"
                  className="form-control"
                  name="startDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.appointmentEntity.startDate)}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="endDateLabel" for="appointment-endDate">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.endDate">End Date</Translate>
                </Label>
                <AvInput
                  id="appointment-endDate"
                  type="datetime-local"
                  className="form-control"
                  name="endDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.appointmentEntity.endDate)}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="statusLabel" for="appointment-status">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.status">Status</Translate>
                </Label>
                <AvInput
                  id="appointment-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && appointmentEntity.status) || 'UNAPPROVED'}
                >
                  <option value="UNAPPROVED">{translate('hospitalmsappfrontendApp.AppointmentStatus.UNAPPROVED')}</option>
                  <option value="COMPLETED">{translate('hospitalmsappfrontendApp.AppointmentStatus.COMPLETED')}</option>
                  <option value="PENDING">{translate('hospitalmsappfrontendApp.AppointmentStatus.PENDING')}</option>
                  <option value="CANCELLED">{translate('hospitalmsappfrontendApp.AppointmentStatus.CANCELLED')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="anamnesisLabel" for="appointment-anamnesis">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.anamnesis">Anamnesis</Translate>
                </Label>
                <AvField id="appointment-anamnesis" type="textarea" name="anamnesis" />
              </AvGroup>
              <AvGroup>
                <Label id="treatmentLabel" for="appointment-treatment">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.treatment">Treatment</Translate>
                </Label>
                <AvField id="appointment-treatment" type="textarea" name="treatment" />
              </AvGroup>
              <AvGroup>
                <Label id="diagnosisLabel" for="appointment-diagnosis">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.diagnosis">Diagnosis</Translate>
                </Label>
                <AvField id="appointment-diagnosis" type="textarea" name="diagnosis" />
              </AvGroup>
              <AvGroup>
                <Label id="prescriptionLabel" for="appointment-prescription">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.prescription">Prescription</Translate>
                </Label>
                <AvField id="appointment-prescription" type="textarea" name="prescription" />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="appointment-description">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.description">Description</Translate>
                </Label>
                <AvField id="appointment-description" type="textarea" name="description" />
              </AvGroup>
              <AvGroup>
                <Label for="appointment-physician">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.physician">Physician</Translate>
                </Label>
                <AvInput id="appointment-physician" type="select" disabled={isPhysician ? true : false} className="form-control" name="physician.id">
                  <option value="" key="0" />
                  {physicians
                    ? physicians.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id + ":" + otherEntity.firstName + " " + otherEntity.lastName}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="appointment-patient">
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.patient">Patient</Translate>
                </Label>
                <AvInput id="appointment-patient" type="select" disabled={isPhysician ? true : false} className="form-control" name="patient.id">
                  <option value="" key="0" />
                  {patients
                    ? patients.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id + ":" + otherEntity.firstName + " " + otherEntity.lastName}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/appointment" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>


    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  inPatients: storeState.inPatient.entities,
  bills: storeState.bill.entities,
  physicians: storeState.physician.entities,
  patients: storeState.patient.entities,
  appointmentEntity: storeState.appointment.entity,
  loading: storeState.appointment.loading,
  updating: storeState.appointment.updating,
  updateSuccess: storeState.appointment.updateSuccess,
  isPhysician: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PHYSICIAN])
});

const mapDispatchToProps = {
  getInPatients,
  getBills,
  getPhysicians,
  getPatients,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentUpdate);
