import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICState, defaultValue } from 'app/shared/model/c-state.model';

export const ACTION_TYPES = {
  FETCH_CSTATE_LIST: 'cState/FETCH_CSTATE_LIST',
  FETCH_BYCOUNTRY_CSTATE_LIST: 'cState/FETCH_BYCOUNTRY_CSTATE_LIST',
  FETCH_CSTATE: 'cState/FETCH_CSTATE',
  CREATE_CSTATE: 'cState/CREATE_CSTATE',
  UPDATE_CSTATE: 'cState/UPDATE_CSTATE',
  DELETE_CSTATE: 'cState/DELETE_CSTATE',
  RESET: 'cState/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICState>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type CStateState = Readonly<typeof initialState>;

// Reducer

export default (state: CStateState = initialState, action): CStateState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CSTATE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BYCOUNTRY_CSTATE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CSTATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CSTATE):
    case REQUEST(ACTION_TYPES.UPDATE_CSTATE):
    case REQUEST(ACTION_TYPES.DELETE_CSTATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CSTATE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BYCOUNTRY_CSTATE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CSTATE):
    case FAILURE(ACTION_TYPES.CREATE_CSTATE):
    case FAILURE(ACTION_TYPES.UPDATE_CSTATE):
    case FAILURE(ACTION_TYPES.DELETE_CSTATE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CSTATE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_BYCOUNTRY_CSTATE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CSTATE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CSTATE):
    case SUCCESS(ACTION_TYPES.UPDATE_CSTATE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CSTATE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/c-states';

// Actions

export const getEntities: ICrudGetAllAction<ICState> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CSTATE_LIST,
    payload: axios.get<ICState>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntitiesByCountryId: ICrudGetAllAction<ICState> = id => ({
  type: ACTION_TYPES.FETCH_BYCOUNTRY_CSTATE_LIST,
  payload: axios.get<ICState>(`${apiUrl}/country/${id}`),
});

export const getEntity: ICrudGetAction<ICState> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CSTATE,
    payload: axios.get<ICState>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ICState> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CSTATE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICState> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CSTATE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICState> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CSTATE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
