import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, logInfo } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { ICountry } from 'app/shared/model/country.model';
import { getEntities as getCountries } from 'app/entities/country/country.reducer';
import { ICState } from 'app/shared/model/c-state.model';
import { getEntities as getCStates, getEntitiesByCountryId } from 'app/entities/c-state/c-state.reducer';
import { getEntity, updateEntity, createEntity, reset } from './patient.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IPatientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const PatientUpdate = (props: IPatientUpdateProps) => {
  const [userId, setUserId] = useState('0');
  const [countryId, setCountryId] = useState('0');
  const [cstateId, setCstateId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const [selectedCountry, setSelectedCountry] = useState('0');

  const { patientEntity, users, countries, cStates, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/patient' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getUsers();
    props.getCountries();
    // props.getCStates();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);


  useEffect(() => {
    if (selectedCountry) {
      props.getEntitiesByCountryId(Number(selectedCountry))
    }
  }, [selectedCountry]);




  const saveEntity = (event, errors, values) => {
    values.birthDate = convertDateTimeToServer(values.birthDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);

    if (errors.length === 0) {
      const entity = {
        ...patientEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const changeCountry = (event) => {
    setSelectedCountry(event.target.value);
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hospitalmsappfrontendApp.patient.home.createOrEditLabel">
            <Translate contentKey="hospitalmsappfrontendApp.patient.home.createOrEditLabel">Create or edit a Patient</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : patientEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="patient-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="patient-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="firstNameLabel" for="patient-firstName">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.firstName">FirstName</Translate>
                </Label>
                <AvField
                  id="patient-firstName"
                  type="text"
                  name="firstName"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="patient-lastName">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.lastName">LastName</Translate>
                </Label>
                <AvField
                  id="patient-lastName"
                  type="text"
                  name="lastName"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="birthDateLabel" for="patient-birthDate">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.birthDate">Birth Date</Translate>
                </Label>
                <AvInput
                  id="patient-birthDate"
                  type="datetime-local"
                  className="form-control"
                  name="birthDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.patientEntity.birthDate)}
                />
              </AvGroup>

              <AvGroup>
                <Label id="emailLabel" for="patient-email">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.email">Email</Translate>
                </Label>

                <AvField
                  name="email"
                  placeholder={translate('global.form.email.placeholder')}
                  type="email"
                  validate={{
                    required: { value: true, errorMessage: translate('global.messages.validate.email.required') },
                    minLength: { value: 5, errorMessage: translate('global.messages.validate.email.minlength') },
                    maxLength: { value: 254, errorMessage: translate('global.messages.validate.email.maxlength') },
                  }}
                />
              </AvGroup>


              <AvGroup>
                <Label id="phoneLabel" for="patient-phone">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.phone">Phone</Translate>
                </Label>
                <AvField
                  id="patient-phone"
                  type="text"
                  name="phone"
                  validate={{
                    required: { value: true, errorMessage: translate('register.messages.validate.phone.required') },
                    pattern: {
                      value: '/^(1\\s|1|)?((\\(\\d{3}\\))|\\d{3})(\\-|\\s)?(\\d{3})(\\-|\\s)?(\\d{4})$/',
                      errorMessage: "Phone number is invalid",
                      maxLength: { value: 12 }
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="genderLabel" for="patient-gender">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.gender">Gender</Translate>
                </Label>
                <AvInput
                  id="patient-gender"
                  type="select"
                  className="form-control"
                  name="gender"
                  value={(!isNew && patientEntity.gender) || 'MALE'}
                >
                  <option value="MALE">{translate('hospitalmsappfrontendApp.Gender.MALE')}</option>
                  <option value="FEMALE">{translate('hospitalmsappfrontendApp.Gender.FEMALE')}</option>
                  <option value="OTHER">{translate('hospitalmsappfrontendApp.Gender.OTHER')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="bloodGroupLabel" for="patient-bloodGroup">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.bloodGroup">Blood Group</Translate>
                </Label>
                <AvInput
                  id="patient-bloodGroup"
                  type="select"
                  className="form-control"
                  name="bloodGroup"
                  value={(!isNew && patientEntity.bloodGroup) || 'Apositive'}
                >
                  <option value="Apositive">{translate('hospitalmsappfrontendApp.BloodGroup.Apositive')}</option>
                  <option value="Anegative">{translate('hospitalmsappfrontendApp.BloodGroup.Anegative')}</option>
                  <option value="Bpositive">{translate('hospitalmsappfrontendApp.BloodGroup.Bpositive')}</option>
                  <option value="Bnegative">{translate('hospitalmsappfrontendApp.BloodGroup.Bnegative')}</option>
                  <option value="Opositive">{translate('hospitalmsappfrontendApp.BloodGroup.Opositive')}</option>
                  <option value="Onegative">{translate('hospitalmsappfrontendApp.BloodGroup.Onegative')}</option>
                  <option value="ABpositive">{translate('hospitalmsappfrontendApp.BloodGroup.ABpositive')}</option>
                  <option value="ABnegative">{translate('hospitalmsappfrontendApp.BloodGroup.ABnegative')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="adressLabel" for="patient-adress">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.adress">Adress</Translate>
                </Label>
                <AvField id="patient-adress" type="text" name="adress" />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="patient-description">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.description">Description</Translate>
                </Label>
                <AvField id="patient-description" type="textarea" name="description" />
              </AvGroup>

              <AvGroup>
                <Label for="patient-user">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.user">User</Translate>
                </Label>
                <AvInput
                  id="patient-user"
                  type="select"
                  className="form-control"
                  name="user.id"
                  value={isNew ? users[0] && users[0].id : patientEntity.user?.id}
                  required
                >
                  {users
                    ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                    : null}
                </AvInput>
                <AvFeedback>
                  <Translate contentKey="entity.validation.required">This field is required.</Translate>
                </AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="patient-country">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.country">Country</Translate>
                </Label>
                <AvInput id="patient-country" type="select" onChange={changeCountry} className="form-control" name="country.id">
                  <option value="" key="0" />
                  {countries
                    ? countries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="patient-cstate">
                  <Translate contentKey="hospitalmsappfrontendApp.patient.cstate">Cstate</Translate>
                </Label>
                <AvInput id="patient-cstate" type="select" className="form-control" name="cstate.id">
                  <option value="" key="0" />
                  {cStates
                    ? cStates.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/patient" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  countries: storeState.country.entities,
  cStates: storeState.cState.entities,
  patientEntity: storeState.patient.entity,
  loading: storeState.patient.loading,
  updating: storeState.patient.updating,
  updateSuccess: storeState.patient.updateSuccess,
});

const mapDispatchToProps = {
  getUsers,
  getEntitiesByCountryId,
  getCountries,
  getCStates,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientUpdate);
