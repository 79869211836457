import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Input, Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getAppointmentsByUserId } from './patient-appointment.reducer';
import { IAppointment } from 'app/shared/model/appointment.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import moment from 'moment';
import { toast } from 'react-toastify';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
export interface IAppointmentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const PatientAppointment = (props: IAppointmentProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const beforeAWeek = (): string => {
    // Today + 1 day - needed if the current day must be included
    const day: Date = new Date();
    day.setDate(day.getDate() + 1);
    const toDate = new Date(day.getFullYear(), day.getMonth(), day.getDate() - 7);
    return toDate.toISOString().slice(0, 10);
  };

  const afterAWeek = (): string => {
    // Today + 1 day - needed if the current day must be included
    const day: Date = new Date();
    day.setDate(day.getDate());
    const toDate = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 7);
    return toDate.toISOString().slice(0, 10);
  };

  const [fromDate, setFromDate] = useState(beforeAWeek());
  const [toDate, setToDate] = useState(afterAWeek());

  const diff2Dates = () => {
    const diff = moment(toDate).diff(moment(fromDate), 'days');
    return diff;
  }

  const onChangeFromDate = evt => setFromDate(evt.target.value);

  const onChangeToDate = evt => setToDate(evt.target.value);



  const getAllEntities = () => {
    if (diff2Dates() < 15) {

      props.getAppointmentsByUserId(fromDate, toDate, props.userId);
    }
    else {
      toast.warn("Days between begin date and end date must be max 15 days");
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getAllEntities();
  }, [fromDate, toDate, props.userId]);


  const { appointmentList, match, loading } = props;
  return (
    <div>
      <h2 id="appointment-heading">Appointments</h2>
      <span>
        <Translate contentKey="audits.filter.from">from</Translate>
      </span>
      <Input type="date" value={fromDate} onChange={onChangeFromDate} name="fromDate" id="fromDate" />
      <span>
        <Translate contentKey="audits.filter.to">to</Translate>
      </span>
      <Input type="date" value={toDate} onChange={onChangeToDate} name="toDate" id="toDate" />
      <div className="table-responsive">
        {appointmentList && appointmentList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>

                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.patient.name">Name</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.startDate">Start Date</Translate>{' '}
                </th>

                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.status">Status</Translate>{' '}
                </th>
              
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.prescription">Prescription</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.createdDate">Created Date</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.appointment.physician">Physician</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {appointmentList.map((appointment, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                      {appointment.id}
                  </td>


                  <td>
                    {appointment.patient ? <Link to={`patient-detail/${appointment.patient.id}`}>{appointment.patient.firstName + " " + appointment.patient.lastName}</Link> : ''}
                  </td>
                  <td>
                    {appointment.startDate ? <TextFormat type="date" value={appointment.startDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                      {appointment.status}
                  </td>

                  <td>{appointment.prescription}</td>

                  <td>
                    {appointment.createdDate ? <TextFormat type="date" value={appointment.createdDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {appointment.physician ? appointment.physician.firstName + " " + appointment.physician.lastName: ''}
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={"tests/appointment/" + appointment.id}
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="hospitalmsappfrontendApp.cTest.showtest">Show Tests</Translate>
                        </span>
                      </Button>
                     {appointment.status==="COMPLETED"? <Button
                        tag={Link}
                        to={"patient-invoice-show/" + appointment.id}
                        color="warning"
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faMoneyBill} />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="hospitalmsappfrontendApp.bill.showinvoice">Show Invoice</Translate>
                        </span>
                      </Button>:null}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.home.notFound">No Appointments found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication, patientAppointment }: IRootState) => ({
  appointmentList: patientAppointment.entities,
  loading: patientAppointment.loading,
  userId: authentication.account.id,
});

const mapDispatchToProps = {
  getAppointmentsByUserId
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientAppointment);
