import { Moment } from 'moment';
import { IAppointment } from 'app/shared/model/appointment.model';
import { PaymentMethod } from 'app/shared/model/enumerations/payment-method.model';

export interface IBill {
  id?: number;
  ssn?: string;
  date?: string;
  billName?: string;
  billDate?: string;
  totalCost?: number;
  paymentMethod?: PaymentMethod;
  description?: string;
  createdDate?: string;
  appointment?: IAppointment;
}

export const defaultValue: Readonly<IBill> = {};
