import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './appointment-tests.reducer';
import { ICTest } from 'app/shared/model/c-test.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAppointmentTestsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AppointmentTests = (props: IAppointmentTestsProps) => {
  useEffect(() => {
    props.getEntities(Number(props.match.params.id));
  }, []);

  const { cTestList, match, loading } = props;
  return (
    <div>
      <h2 id="c-test-heading">
        <Translate contentKey="hospitalmsappfrontendApp.cTest.home.title">C Tests</Translate>
      </h2>
      <div className="table-responsive">
        {cTestList && cTestList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="global.field.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.cTest.name">Name</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.cTest.description">Description</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.cTest.date">Date</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.cTest.createdDate">Created Date</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.cTest.appointment">Appointment</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {cTestList.map((cTest, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${cTest.id}`} color="link" size="sm">
                      {cTest.id}
                    </Button>
                  </td>
                  <td>{cTest.name}</td>
                  <td>{cTest.description}</td>
                  <td>{cTest.date ? <TextFormat type="date" value={cTest.date} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{cTest.createdDate ? <TextFormat type="date" value={cTest.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{cTest.appointment ? <Link to={`appointment/${cTest.appointment.id}`}>{cTest.appointment.id}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/testresult/test/${cTest.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          View Results
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hospitalmsappfrontendApp.cTest.home.notFound">No C Tests found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ appointmentTests,authentication }: IRootState) => ({
  cTestList: appointmentTests.entities,
  loading: appointmentTests.loading,
  isPatient:authentication.account.isPatient
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentTests);
