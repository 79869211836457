import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from './menu-components';
import { faBed, faCalendarDay, faCity, faEnvelopeOpenText, faFlag, faGlasses, faHotTub, faRestroom, faTextWidth, faUser, faUserInjured, faUserMd, faUserNurse } from '@fortawesome/free-solid-svg-icons';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <MenuItem toggle={props.toggle} icon={faUserInjured} to="/patient">
      <Translate contentKey="global.menu.entities.patient" />
    </MenuItem>
    <MenuItem toggle={props.toggle}  icon={faUserNurse} to="/staff">
      <Translate contentKey="global.menu.entities.staff" />
    </MenuItem>
    <MenuItem toggle={props.toggle}   icon={faUserMd}  to="/physician">
      <Translate contentKey="global.menu.entities.physician" />
    </MenuItem>
    <MenuItem toggle={props.toggle}  icon={faCalendarDay} to="/appointment">
      <Translate contentKey="global.menu.entities.appointment" />
    </MenuItem>
    {/* <MenuItem icon="asterisk" to="/c-test">
      <Translate contentKey="global.menu.entities.cTest" />
    </MenuItem> */}
    <MenuItem toggle={props.toggle}  icon={faGlasses}to="/c-test-item">
      <Translate contentKey="global.menu.entities.cTestItem" />
    </MenuItem>
    {/* <MenuItem icon="asterisk" to="/c-test-result">
      <Translate contentKey="global.menu.entities.cTestResult" />
    </MenuItem> */}
    {/* <MenuItem icon="asterisk" to="/bill">
      <Translate contentKey="global.menu.entities.bill" />
    </MenuItem> */}
    <MenuItem toggle={props.toggle}  icon={faBed} to="/room">
      <Translate contentKey="global.menu.entities.room" />
    </MenuItem>
    {/* <MenuItem toggle={props.toggle}  icon="asterisk" to="/in-patient">
      <Translate contentKey="global.menu.entities.inPatient" />
    </MenuItem> */}
    <MenuItem toggle={props.toggle}  icon={faFlag} to="/country">
      <Translate contentKey="global.menu.entities.country" />
    </MenuItem>
    <MenuItem toggle={props.toggle}  icon={faCity} to="/c-state">
      <Translate contentKey="global.menu.entities.cState" />
    </MenuItem>

    <MenuItem toggle={props.toggle}   icon={faEnvelopeOpenText} to="/c-message">
      <Translate contentKey="global.menu.entities.cMessage" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
