import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IBill, defaultValue } from 'app/shared/model/bill.model';

export const ACTION_TYPES = {
  FETCH_INVOICE: 'bill/FETCH_INVOICE',
  UPDATE_INVOICE: 'bill/UPDATE_INVOICE',
  RESET: 'bill/RESET_INVOICE',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IBill>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type InvoiceState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceState = initialState, action): InvoiceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE_INVOICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INVOICE):
    case FAILURE(ACTION_TYPES.UPDATE_INVOICE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.UPDATE_INVOICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/bills';

// Actions

export const getEntity: ICrudGetAction<IBill> = id => {
  const requestUrl = process.env.REACT_APP_API_URL + '/appointments/bill/' + `${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICE,
    payload: axios.get<IBill>(requestUrl),
  };
};
export const getEntityByAppointmentId: ICrudGetAction<IBill> = id => {
  const requestUrl = `${apiUrl}/appointment/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICE,
    payload: axios.get<IBill>(requestUrl),
  };
};
export const updateEntity: ICrudPutAction<IBill> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INVOICE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
