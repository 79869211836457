import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './physician-appointment.reducer';
import { IAppointment } from 'app/shared/model/appointment.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAppointmentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PhysicianAppointmentDetail = (props: IAppointmentDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { appointmentEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="hospitalmsappfrontendApp.appointment.detail.title">Appointment</Translate> [<b>{appointmentEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="startDate">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>
            {appointmentEntity.startDate ? <TextFormat value={appointmentEntity.startDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>
            {appointmentEntity.endDate ? <TextFormat value={appointmentEntity.endDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="status">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.status">Status</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.status}</dd>
          <dt>
            <span id="anamnesis">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.anamnesis">Anamnesis</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.anamnesis}</dd>
          <dt>
            <span id="treatment">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.treatment">Treatment</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.treatment}</dd>
          <dt>
            <span id="diagnosis">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.diagnosis">Diagnosis</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.diagnosis}</dd>
          <dt>
            <span id="prescription">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.prescription">Prescription</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.prescription}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.description">Description</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.description}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="hospitalmsappfrontendApp.appointment.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {appointmentEntity.createdDate ? (
              <TextFormat value={appointmentEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.appointment.physician">Physician</Translate>
          </dt>
          <dd>{appointmentEntity.physician ? appointmentEntity.physician.id : ''}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.appointment.patient">Patient</Translate>
          </dt>
          <dd>{appointmentEntity.patient ? appointmentEntity.patient.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/appointment" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/appointment/${appointmentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ appointmentPhysician }: IRootState) => ({
  appointmentEntity: appointmentPhysician.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianAppointmentDetail);
