import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat, logInfo } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './payment-invoice.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICStateDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const PaymentDetail = (props: ICStateDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);


  const { paymentEntity } = props;
  return (
    <div>
      <Table responsive>
        <thead>
        <tr style={{ textAlign: "center"}}>
                        <th style={{ fontSize: "45px" }} >
                            PAYMENT DETAIL
                        </th>
                        <th />
                    </tr>
          <tr>
            <th >
              SSN:
            </th>

            <th >
              {paymentEntity && paymentEntity.ssn ? paymentEntity.ssn : ""}
            </th>
            <th />
          </tr>

          <tr>
            <th >
              Name:
            </th>

            <th >
              {paymentEntity && paymentEntity.patient ? paymentEntity.patient.firstName : ""}
            </th>
            <th />
          </tr>


          <tr>
            <th >
              Last Name:
            </th>

            <th >
              {paymentEntity && paymentEntity.patient ? paymentEntity.patient.lastName : ""}
            </th>
            <th />
          </tr>

          <tr>
            <th >
              Exam Fee:
            </th>

            <th >
              {paymentEntity && paymentEntity.appointmentCost ? paymentEntity.appointmentCost : ""}
            </th>
            <th />
          </tr>
          {
            paymentEntity && paymentEntity.lengthOfStay ?
              <tr>
                <th >
                  Staying Fee:
                </th>

                <th >
                  <td>{paymentEntity && paymentEntity.lengthOfStay ? paymentEntity.lengthOfStay * paymentEntity.room.price : ""}</td>
                </th>
                <th />
              </tr>
              : null
          }
          {
            paymentEntity && paymentEntity.testItems && paymentEntity.testItems.length > 0 ?
              <tr>
                <td>
                  <Table>
                    <thead>
                      <tr>
                        <td>
                          Test Item Name
                        </td>
                        <td>
                          Test Item Fee
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentEntity && paymentEntity.testItems ? paymentEntity.testItems.map((item, i) => (
                        <tr key={`entity-${i}`}>
                          <td style={{ fontSize: "15px" }}>{item.name}</td>
                          <td style={{ fontSize: "15px" }}>{item.price}</td>
                        </tr>
                      )) : null}
                    </tbody>
                  </Table>
                </td>
              </tr> : null
          }
          <br />
        </thead>
        <tbody>
          <tr>
            <td style={{ fontSize: "20px" }}>TOTAL COST</td>

            <td style={{ fontSize: "20px" }}>{paymentEntity.totalCost}$ </td>
          </tr>
        </tbody>

      </Table>
      {paymentEntity && paymentEntity.billId ? <Button tag={Link} to={"/invoice-show/" + paymentEntity.billId} color="success" size="sm"> Show Invoice</Button> : <Button tag={Link} to={"/invoice-update/" + props.match.params.id} color="danger" size="sm"> Create Invoice</Button>}
    </div>
  );
};

const mapStateToProps = ({ paymentInvoice }: IRootState) => ({
  paymentEntity: paymentInvoice.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);
