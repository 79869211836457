import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './room.reducer';
import { IRoom } from 'app/shared/model/room.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IRoomUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const RoomUpdate = (props: IRoomUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { roomEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/room' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.createdDate = convertDateTimeToServer(values.createdDate);

    if (errors.length === 0) {
      const entity = {
        ...roomEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hospitalmsappfrontendApp.room.home.createOrEditLabel">
            <Translate contentKey="hospitalmsappfrontendApp.room.home.createOrEditLabel">Create or edit a Room</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : roomEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="room-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="room-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="roomNumberLabel" for="room-roomNumber">
                  <Translate contentKey="hospitalmsappfrontendApp.room.roomNumber">Room Number</Translate>
                </Label>
                <AvField
                  id="room-roomNumber"
                  type="string"
                  className="form-control"
                  name="roomNumber"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="roomTypeLabel" for="room-roomType">
                  <Translate contentKey="hospitalmsappfrontendApp.room.roomType">Room Type</Translate>
                </Label>
                <AvInput
                  id="room-roomType"
                  type="select"
                  className="form-control"
                  name="roomType"
                  value={(!isNew && roomEntity.roomType) || 'TWIN'}
                >
                  <option value="TWIN">{translate('hospitalmsappfrontendApp.RoomType.TWIN')}</option>
                  <option value="DELUXE">{translate('hospitalmsappfrontendApp.RoomType.DELUXE')}</option>
                  <option value="PREMIUM_DELUXE">{translate('hospitalmsappfrontendApp.RoomType.PREMIUM_DELUXE')}</option>
                  <option value="SUITE">{translate('hospitalmsappfrontendApp.RoomType.SUITE')}</option>
                  <option value="DAYCARE">{translate('hospitalmsappfrontendApp.RoomType.DAYCARE')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup check>
                <Label id="statusLabel">
                  <AvInput id="room-status" type="checkbox" className="form-check-input" name="status" />
                  <Translate contentKey="hospitalmsappfrontendApp.room.status">Status</Translate>
                </Label>
              </AvGroup>
              <AvGroup>
                <Label id="priceLabel" for="room-price">
                  <Translate contentKey="hospitalmsappfrontendApp.room.price">Price</Translate>
                </Label>
                <AvField
                  id="room-price"
                  type="text"
                  name="price"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="room-description">
                  <Translate contentKey="hospitalmsappfrontendApp.room.description">Description</Translate>
                </Label>
                <AvField id="room-description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="createdDateLabel" for="room-createdDate">
                  <Translate contentKey="hospitalmsappfrontendApp.room.createdDate">Created Date</Translate>
                </Label>
                <AvInput
                  id="room-createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.roomEntity.createdDate)}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/room" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  roomEntity: storeState.room.entity,
  loading: storeState.room.loading,
  updating: storeState.room.updating,
  updateSuccess: storeState.room.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RoomUpdate);
