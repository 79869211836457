import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, logInfo, translate } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICTestItem, defaultValue } from 'app/shared/model/c-test-item.model';
import { ITestRequest } from 'app/shared/model/c-test-request.model';

export const ACTION_TYPES = {
  FETCH_CTESTITEM_LIST_NOPAGE: 'ps-cTestItem/FETCH_CTESTITEM_LIST_NOPAGE',
  CREATE_TESTREQUEST: 'ps-cTestItem/CREATE_TESTREQUEST',
  RESET: 'ps-cTestItem/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICTestItem>,
  updating: false,
  updateSuccess: false,
  registrationFailure: false,
  registrationSuccess: false,
  testEntity: defaultValue,
};

export type PhysicianTestItemState = Readonly<typeof initialState>;

// Reducer

export default (state: PhysicianTestItemState = initialState, action): PhysicianTestItemState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_TESTREQUEST):
      return {
        ...state,
        loading: true,
        updating: true,
      };

    case FAILURE(ACTION_TYPES.CREATE_TESTREQUEST):
      return {
        ...initialState,
        registrationFailure: true,
        errorMessage: action.payload.response.data.errorKey,
      };

    case REQUEST(ACTION_TYPES.FETCH_CTESTITEM_LIST_NOPAGE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CTESTITEM_LIST_NOPAGE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.CREATE_TESTREQUEST):
      return {
        ...initialState,
        updateSuccess: true,
        testEntity: action.payload.data,
        updating: false,
        registrationSuccess: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_CTESTITEM_LIST_NOPAGE):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/c-test-items';

// Actions

export const getEntities = () => {
  const requestUrl = `${apiUrl + '/all'}`;
  return {
    type: ACTION_TYPES.FETCH_CTESTITEM_LIST_NOPAGE,
    payload: axios.get(requestUrl),
  };
};

// export const sendTestRequest = (value) => ({
//   type: ACTION_TYPES.CREATE_TESTREQUEST,
//   payload: axios.post('api/c-tests/request', value),
//   meta: {
//     successMessage: translate('hospitalmsappfrontendApp.appointment.success'),
//   },
// });

export const sendTestRequest: ICrudPutAction<ITestRequest> = value => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TESTREQUEST,
    payload: axios.post(process.env.REACT_APP_API_URL +'/c-tests/request', value),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
