import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './staff.reducer';
import { IStaff } from 'app/shared/model/staff.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IStaffDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const StaffDetail = (props: IStaffDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { staffEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="hospitalmsappfrontendApp.staff.detail.title">Staff</Translate> [<b>{staffEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="ssn">
              <Translate contentKey="hospitalmsappfrontendApp.staff.ssn">SSN</Translate>
            </span>
          </dt>
          <dd>{staffEntity&&staffEntity.user?staffEntity.user.ssn:null}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="hospitalmsappfrontendApp.staff.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{staffEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="hospitalmsappfrontendApp.staff.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{staffEntity.lastName}</dd>
          <dt>
            <span id="birthDate">
              <Translate contentKey="hospitalmsappfrontendApp.staff.birthDate">Birth Date</Translate>
            </span>
          </dt>
          <dd>{staffEntity.birthDate ? <TextFormat value={staffEntity.birthDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="hospitalmsappfrontendApp.staff.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{staffEntity.phone}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="hospitalmsappfrontendApp.staff.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{staffEntity.gender}</dd>
          <dt>
            <span id="bloodGroup">
              <Translate contentKey="hospitalmsappfrontendApp.staff.bloodGroup">Blood Group</Translate>
            </span>
          </dt>
          <dd>{staffEntity.bloodGroup}</dd>
          <dt>
            <span id="adress">
              <Translate contentKey="hospitalmsappfrontendApp.staff.adress">Adress</Translate>
            </span>
          </dt>
          <dd>{staffEntity.adress}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hospitalmsappfrontendApp.staff.description">Description</Translate>
            </span>
          </dt>
          <dd>{staffEntity.description}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="hospitalmsappfrontendApp.staff.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{staffEntity.createdDate ? <TextFormat value={staffEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.staff.user">User</Translate>
          </dt>
          <dd>{staffEntity.user ? staffEntity.user.login : ''}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.staff.country">Country</Translate>
          </dt>
          <dd>{staffEntity.country ? staffEntity.country.name : ''}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.staff.cstate">Cstate</Translate>
          </dt>
          <dd>{staffEntity.cstate ? staffEntity.cstate.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/staff" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/staff/${staffEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ staff }: IRootState) => ({
  staffEntity: staff.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetail);
