import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './physician-patient.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IPatientDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const PhysicianPatientDetail = (props: IPatientDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { patientEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="hospitalmsappfrontendApp.patient.detail.title">Patient</Translate> [<b>{patientEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="ssn">
              <Translate contentKey="hospitalmsappfrontendApp.patient.ssn">SSN</Translate>
            </span>
          </dt>
          <dd>{patientEntity&&patientEntity.user?patientEntity.user.ssn:null}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="hospitalmsappfrontendApp.patient.firstName">firstName</Translate>
            </span>
          </dt>
          <dd>{patientEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="hospitalmsappfrontendApp.patient.lastName">lastName</Translate>
            </span>
          </dt>
          <dd>{patientEntity.lastName}</dd>
          <dt>
            <span id="birthDate">
              <Translate contentKey="hospitalmsappfrontendApp.patient.birthDate">Birth Date</Translate>
            </span>
          </dt>
          <dd>{patientEntity.birthDate ? <TextFormat value={patientEntity.birthDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="hospitalmsappfrontendApp.patient.phone">Phone</Translate>
            </span>
          </dt>

          <dt>
            <span id="email">
              <Translate contentKey="hospitalmsappfrontendApp.patient.email">Email</Translate>
            </span>
          </dt>

          <dd>{patientEntity.email}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="hospitalmsappfrontendApp.patient.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{patientEntity.gender}</dd>
          <dt>
            <span id="bloodGroup">
              <Translate contentKey="hospitalmsappfrontendApp.patient.bloodGroup">Blood Group</Translate>
            </span>
          </dt>
          <dd>{patientEntity.bloodGroup}</dd>
          <dt>
            <span id="adress">
              <Translate contentKey="hospitalmsappfrontendApp.patient.adress">Adress</Translate>
            </span>
          </dt>
          <dd>{patientEntity.adress}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hospitalmsappfrontendApp.patient.description">Description</Translate>
            </span>
          </dt>
          <dd>{patientEntity.description}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="hospitalmsappfrontendApp.patient.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {patientEntity.createdDate ? <TextFormat value={patientEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.patient.user">User</Translate>
          </dt>
          <dd>{patientEntity.user ? patientEntity.user.login : ''}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.patient.country">Country</Translate>
          </dt>
          <dd>{patientEntity.country ? patientEntity.country.name : ''}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.patient.cstate">Cstate</Translate>
          </dt>
          <dd>{patientEntity.cstate ? patientEntity.cstate.name : ''}</dd>
        </dl>

        {
          props.isPhysician ?
            <Button tag={Link} to="/physician-appointment" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button> :
            <Button tag={Link} to={props.isPatient ? "/patient-appointment" : "/search-patient"} replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        }

        &nbsp;

        {props.isStaff ?
          <Button tag={Link} to={`/patient-update/${patientEntity.id}`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button> : null
        }

      </Col>
    </Row>
  );
};

const mapStateToProps = ({ physicianPatient, authentication }: IRootState) => ({
  patientEntity: physicianPatient.entity,
  isPatient: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PATIENT]),
  isStaff: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.STAFF]),
  isPhysician: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PHYSICIAN])
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianPatientDetail);
