import axios from 'axios';
import { ICrudPutAction, ICrudGetAction, ICrudGetAllAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatient, defaultValue } from 'app/shared/model/patient.model';
import { ISearchPatientRequest } from 'app/shared/model/searchPatientRequest.model';
import { cleanEntity } from 'app/shared/util/entity-utils';

export const ACTION_TYPES = {
  FETCH_PHYSICIANPATIENT_LIST: 'ps-patient/FETCH_PHYSICIANPATIENT_LIST',
  FETCH_PHYSICIANPATIENT: 'ps-patient/FETCH_PHYSICIANPATIENT',
  FETCH_SEARCHPATIENT: 'ps-patient/FETCH_SEARCHPATIENT',
  PS_CREATE_PATIENT: 'ps-patient/PS_CREATE_PATIENT',
  PS_UPDATE_PATIENT: 'ps-patient/PS_UPDATE_PATIENT',
  RESET: 'ps-patient/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatient>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type PhysicianPatientState = Readonly<typeof initialState>;

// Reducer

export default (state: PhysicianPatientState = initialState, action): PhysicianPatientState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PHYSICIANPATIENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PHYSICIANPATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.PS_UPDATE_PATIENT):
    case REQUEST(ACTION_TYPES.PS_CREATE_PATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_SEARCHPATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_PHYSICIANPATIENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PHYSICIANPATIENT):
    case FAILURE(ACTION_TYPES.FETCH_SEARCHPATIENT):
    case FAILURE(ACTION_TYPES.PS_UPDATE_PATIENT):
    case FAILURE(ACTION_TYPES.PS_CREATE_PATIENT):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PHYSICIANPATIENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PHYSICIANPATIENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SEARCHPATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entities: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.PS_UPDATE_PATIENT):
    case SUCCESS(ACTION_TYPES.PS_CREATE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/patients';

export const getEntity: ICrudGetAction<IPatient> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PHYSICIANPATIENT,
    payload: axios.get<IPatient>(requestUrl),
  };
};

export const getEntities: ICrudGetAllAction<IPatient> = (page, size, sort) => {
  const requestUrl = `${process.env.REACT_APP_API_URL}/patient/physician${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PHYSICIANPATIENT_LIST,
    payload: axios.get<IPatient>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const searchPatient = (page, size, sort, ssn) => {
  let requestUrl = `${process.env.REACT_APP_API_URL}/patients/search${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  if (ssn) {
    requestUrl += `&ssn=${ssn}`;
  }
  return {
    type: ACTION_TYPES.FETCH_PHYSICIANPATIENT_LIST,
    payload: axios.get(requestUrl),
  };
};

export const updateEntity: ICrudPutAction<IPatient> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PS_UPDATE_PATIENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const createEntity: ICrudPutAction<IPatient> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PS_CREATE_PATIENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
