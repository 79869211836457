import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';
import { IAppointment } from 'app/shared/model/appointment.model';
import { ICountry } from 'app/shared/model/country.model';
import { ICState } from 'app/shared/model/c-state.model';
import { Gender } from 'app/shared/model/enumerations/gender.model';
import { BloodGroup } from 'app/shared/model/enumerations/blood-group.model';
import { Speciality } from './enumerations/speciality.model';

export interface IPhysician {
  id?: number;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  phone?: string;
  gender?: Gender;
  bloodGroup?: BloodGroup;
  adress?: string;
  speciality?: Speciality;
  description?: string;
  createdDate?: string;
  image?: any;
  imageContentType?: string;
  user?: IUser;
  appointments?: IAppointment[];
  country?: ICountry;
  cstate?: ICState;
  examFee?: number;
}

export const defaultValue: Readonly<IPhysician> = {};
