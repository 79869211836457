import { Moment } from 'moment';
import { ICTestItem } from 'app/shared/model/c-test-item.model';
import { IAppointment } from 'app/shared/model/appointment.model';
import { IPatient } from 'app/shared/model/patient.model';
import { IRoom } from './room.model';

export interface IPaymentInfo {
  appointmentCost?: number;
  lengthOfStay?: number;
  testItems?: ICTestItem[];
  patient?: IPatient;
  room?: IRoom;
  appointment?: IAppointment;
  totalCost?: number;
  billId?: number;
  ssn?: string;
}

export const defaultValue: Readonly<IPaymentInfo> = {};
