import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { openFile, byteSize,Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './physician.reducer';
import { IPhysician } from 'app/shared/model/physician.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IPhysicianDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PhysicianDetail = (props: IPhysicianDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { physicianEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="hospitalmsappfrontendApp.physician.detail.title">Physician</Translate> [<b>{physicianEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="ssn">
              <Translate contentKey="hospitalmsappfrontendApp.physician.ssn">SSN</Translate>
            </span>
          </dt>
          <dd>{physicianEntity&&physicianEntity.user.ssn?physicianEntity.user.ssn:null}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="hospitalmsappfrontendApp.physician.firstName">FirstName</Translate>
            </span>
          </dt>
          <dd>{physicianEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="hospitalmsappfrontendApp.physician.lastName">LastName</Translate>
            </span>
          </dt>
          <dd>{physicianEntity.lastName}</dd>
          <dt>
            <span id="birthDate">
              <Translate contentKey="hospitalmsappfrontendApp.physician.birthDate">Birth Date</Translate>
            </span>
          </dt>
          <dd>
            {physicianEntity.birthDate ? <TextFormat value={physicianEntity.birthDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="phone">
              <Translate contentKey="hospitalmsappfrontendApp.physician.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{physicianEntity.phone}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="hospitalmsappfrontendApp.physician.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{physicianEntity.gender}</dd>
          <dt>
            <span id="bloodGroup">
              <Translate contentKey="hospitalmsappfrontendApp.physician.bloodGroup">Blood Group</Translate>
            </span>
          </dt>
          <dd>{physicianEntity.bloodGroup}</dd>
          <dt>
            <span id="adress">
              <Translate contentKey="hospitalmsappfrontendApp.physician.adress">Adress</Translate>
            </span>
          </dt>
          <dd>{physicianEntity.adress}</dd>

          <dt>
            <span id="speciality">
              <Translate contentKey="hospitalmsappfrontendApp.physician.speciality">Speciality</Translate>
            </span>
          </dt>
          <dd>{physicianEntity.speciality}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="hospitalmsappfrontendApp.physician.description">Description</Translate>
            </span>
          </dt>
          <dd>{physicianEntity.description}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="hospitalmsappfrontendApp.physician.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {physicianEntity.createdDate ? <TextFormat value={physicianEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>

          <dt>
            <span id="image">
              <Translate contentKey="hospitalmsappfrontendApp.physician.image">Image</Translate>
            </span>
          </dt>
          <dd>
            {physicianEntity.image ? (
              <div>
                {physicianEntity.imageContentType ? (
                  <a onClick={openFile(physicianEntity.imageContentType, physicianEntity.image)}>
                    <img src={`data:${physicianEntity.imageContentType};base64,${physicianEntity.image}`} style={{ maxHeight: '30px' }} />
                  </a>
                ) : null}
                <span>
                  {physicianEntity.imageContentType}, {byteSize(physicianEntity.image)}
                </span>
              </div>
            ) : null}
          </dd>

          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.physician.user">User</Translate>
          </dt>
          <dd>{physicianEntity.user ? physicianEntity.user.login : ''}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.physician.country">Country</Translate>
          </dt>
          <dd>{physicianEntity.country ? physicianEntity.country.name : ''}</dd>
          <dt>
            <Translate contentKey="hospitalmsappfrontendApp.physician.cstate">Cstate</Translate>
          </dt>
          <dd>{physicianEntity.cstate ? physicianEntity.cstate.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/physician" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/physician/${physicianEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ physician }: IRootState) => ({
  physicianEntity: physician.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianDetail);
