import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import Footer from './tpui/Footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import HeaderUI from './tpui/Header';
import ScrollToTop from './shared/util/ScrollToTop';
const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  useEffect(() => {
    props.getSession();
    props.getProfile();
  }, []);

  return (
    <Router basename={baseHref}>
      <ScrollToTop/>
      <div className="app-container">
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
{/*           <HeaderUI/>*/}          
          <Header
            isAuthenticated={props.isAuthenticated}
            isAdmin={props.isAdmin}
            isPhysician={props.isPhysician}
            isStaff={props.isStaff}
            isPatient={props.isPatient}
            currentLocale={props.currentLocale}
            onLocaleChange={props.setLocale}
            ribbonEnv={props.ribbonEnv}
            isInProduction={props.isInProduction}
            isSwaggerEnabled={props.isSwaggerEnabled}
            login={props.login}
            firstName={props.firstName}
            lastName={props.lastName}
            userId={props.userId}
          />
      
        </ErrorBoundary>
        
        <div id="app-view-container">
       
            
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
    
         
          <Footer></Footer>
        </div>
      </div>
    </Router>
  );
};

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isPhysician:hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PHYSICIAN]),
  isStaff:hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.STAFF]),
  isPatient:hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PATIENT]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  
  firstName:authentication.account.firstName,
  lastName:authentication.account.lastName,
  userId:authentication.account.id,
  login:authentication.account.login,
  account:authentication.account,
});

const mapDispatchToProps = { setLocale, getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
