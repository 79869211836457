import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, ICrudGetAction, ICrudDeleteAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IInPatient } from 'app/shared/model/in-patient.model';
import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity } from './in-patient.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IInPatientDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const InPatientDeleteDialog = (props: IInPatientDeleteDialogProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const handleClose = () => {
    if(props.isPhysician || props.isStaff)
    props.history.push('/ps-in-patient');
    else
    props.history.push('/in-patient');
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const confirmDelete = () => {
    props.deleteEntity(props.inPatientEntity.id);
  };

  const { inPatientEntity } = props;
  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="hospitalmsappfrontendApp.inPatient.delete.question">
        <Translate contentKey="hospitalmsappfrontendApp.inPatient.delete.question" interpolate={{ id: inPatientEntity.id }}>
          Are you sure you want to delete this InPatient?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-inPatient" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const mapStateToProps = (storeState: IRootState) => ({
  inPatientEntity: storeState.inPatient.entity,
  updateSuccess: storeState.inPatient.updateSuccess,
  isPhysician: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PHYSICIAN]),
  isStaff: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.STAFF])
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InPatientDeleteDialog);
