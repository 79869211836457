import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

const AccountMenuItemsAuthenticated =(props)=> {
  return(
  <>
    <MenuItem toggle={props.toggle} icon="wrench" to="/account/settings">
      <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </MenuItem>
    <MenuItem toggle={props.toggle} icon="lock" to="/account/password">
      <Translate contentKey="global.menu.account.password">Password</Translate>
    </MenuItem>
    <MenuItem toggle={props.toggle} icon="sign-out-alt" to="/logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
  )
}

const AccountMenuItems =(props)=> {
  return(
  <>
    <MenuItem toggle={props.toggle} id="login-item" icon="sign-in-alt" to="/login">
      <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </MenuItem>
    <MenuItem toggle={props.toggle} icon="sign-in-alt" to="/account/register">
      <Translate contentKey="global.menu.account.register">Register</Translate>
    </MenuItem>
  </>)
}

export interface DropDownItem {
  isAuthenticated?:boolean;
  name?:string;
  toggle?:any;
}
export const AccountMenu = ({toggle,name="", isAuthenticated = false }:DropDownItem) => (
  <NavDropdown toggle={toggle}  icon="user" name={name} id="account-menu">
    {isAuthenticated ? <AccountMenuItemsAuthenticated toggle={toggle}/> : <AccountMenuItems toggle={toggle}/>}
  </NavDropdown>
);

export default AccountMenu;
