import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getCountries } from 'app/entities/country/country.reducer';
import { ICState } from 'app/shared/model/c-state.model';
import { getEntities as getCStates, getEntitiesByCountryId } from 'app/entities/c-state/c-state.reducer';
import { getEntity, updateEntity, createEntity, reset } from './staff.reducer';
import { IStaff } from 'app/shared/model/staff.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { getUsersNoPatient } from 'app/modules/administration/user-management/user-management.reducer';
import { searchUser, resetUser } from 'app/entities/physician/user-search-reducer';
export interface IStaffUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const StaffUpdate = (props: IStaffUpdateProps) => {
  const [userId, setUserId] = useState('0');
  const [countryId, setCountryId] = useState('0');
  const [cstateId, setCstateId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [selectedCountry, setSelectedCountry] = useState('0');

  const [ssn, setSSN] = useState('');
  const [isCheck,setCheck]=useState(false);

  const {foundUser, staffEntity, users, countries, cStates, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/staff' + props.location.search);
    props.resetUser();
    setCheck(false);
  };

  useEffect(() => {
    if (isNew) {
      setCheck(false);
      props.reset();
      props.resetUser();
    } else {
      props.getEntity(props.match.params.id);
      setCheck(false);
    }

  
    props.getUsersNoPatient();
    props.getCountries();
    props.getCStates();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.birthDate = convertDateTimeToServer(values.birthDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);

    if(isCheck){
      values.user=foundUser;
    }

    if (errors.length === 0) {
      const entity = {
        ...staffEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      props.getEntitiesByCountryId(Number(selectedCountry))
    }
  }, [selectedCountry]);

  const changeCountry = (event) => {
    setSelectedCountry(event.target.value);
  }


  const clickSearch = (e) => {
    e.preventDefault();
    props.searchUser(ssn);
  }

  const changeSearchSSN=(event) => {
    setSSN(event.target.value);
  }

  const changeCheckBox=(event)=>{
    setCheck(event.target.checked);
  }



  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="hospitalmsappfrontendApp.staff.home.createOrEditLabel">
            <Translate contentKey="hospitalmsappfrontendApp.staff.home.createOrEditLabel">Create or edit a Staff</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : staffEntity} onSubmit={saveEntity}>

<Row style={{margin:"0px"}}>
              <Col sm="2">
                <AvGroup>
                 
                  <AvInput type="checkbox" onChange={changeCheckBox} name="useSSNSearch" />
                     <Label check for="checkbox"> Use Search</Label>
                  </AvGroup>
                </Col>

                <Col sm="10">
                <AvGroup>
                  <Label>
                    SSN
                  </Label>
                  <AvField type="text"
                   placeHolder="000-00-0000"
                    name="searchSSN" onChange={changeSearchSSN} />
                  <Button type="" onClick={(event) => clickSearch(event)}>Search User</Button>
                  </AvGroup>
                </Col>
              </Row>


              {!isNew ? (
                <AvGroup>
                  <Label for="staff-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="staff-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="firstNameLabel" for="staff-firstName">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.firstName">FirstName</Translate>
                </Label>
                <AvField
                  id="staff-firstName"
                  type="text"
                  name="firstName"
                  value={isCheck&&foundUser?foundUser.firstName:null}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="staff-lastName">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.lastName">Last Name</Translate>
                </Label>
                <AvField
                  id="staff-lastName"
                  type="text"
                  name="lastName"
                  value={isCheck&&foundUser?foundUser.lastName:null}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="birthDateLabel" for="staff-birthDate">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.birthDate">Birth Date</Translate>
                </Label>
                <AvInput
                  id="staff-birthDate"
                  type="datetime-local"
                  className="form-control"
                  name="birthDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.staffEntity.birthDate)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="phoneLabel" for="staff-phone">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.phone">Phone</Translate>
                </Label>
                <AvField
                  id="staff-phone"
                  type="text"
                  name="phone"
                  validate={{
                    required: { value: true, errorMessage: translate('register.messages.validate.phone.required') },
                    pattern: {
                      value: '/^(1\\s|1|)?((\\(\\d{3}\\))|\\d{3})(\\-|\\s)?(\\d{3})(\\-|\\s)?(\\d{4})$/',
                      errorMessage: "Phone number is invalid",
                      maxLength: { value: 12 }
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="genderLabel" for="staff-gender">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.gender">Gender</Translate>
                </Label>
                <AvInput
                  id="staff-gender"
                  type="select"
                  className="form-control"
                  name="gender"
                  value={(!isNew && staffEntity.gender) || 'MALE'}
                >
                  <option value="MALE">{translate('hospitalmsappfrontendApp.Gender.MALE')}</option>
                  <option value="FEMALE">{translate('hospitalmsappfrontendApp.Gender.FEMALE')}</option>
                  <option value="OTHER">{translate('hospitalmsappfrontendApp.Gender.OTHER')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="bloodGroupLabel" for="staff-bloodGroup">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.bloodGroup">Blood Group</Translate>
                </Label>
                <AvInput
                  id="staff-bloodGroup"
                  type="select"
                  className="form-control"
                  name="bloodGroup"
                  value={(!isNew && staffEntity.bloodGroup) || 'Apositive'}
                >
                  <option value="Apositive">{translate('hospitalmsappfrontendApp.BloodGroup.Apositive')}</option>
                  <option value="Anegative">{translate('hospitalmsappfrontendApp.BloodGroup.Anegative')}</option>
                  <option value="Bpositive">{translate('hospitalmsappfrontendApp.BloodGroup.Bpositive')}</option>
                  <option value="Bnegative">{translate('hospitalmsappfrontendApp.BloodGroup.Bnegative')}</option>
                  <option value="Opositive">{translate('hospitalmsappfrontendApp.BloodGroup.Opositive')}</option>
                  <option value="Onegative">{translate('hospitalmsappfrontendApp.BloodGroup.Onegative')}</option>
                  <option value="ABpositive">{translate('hospitalmsappfrontendApp.BloodGroup.ABpositive')}</option>
                  <option value="ABnegative">{translate('hospitalmsappfrontendApp.BloodGroup.ABnegative')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="adressLabel" for="staff-adress">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.adress">Adress</Translate>
                </Label>
                <AvField id="staff-adress" type="text" name="adress" />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="staff-description">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.description">Description</Translate>
                </Label>
                <AvField id="staff-description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="createdDateLabel" for="staff-createdDate">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.createdDate">Created Date</Translate>
                </Label>
                <AvInput
                  id="staff-createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.staffEntity.createdDate)}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label for="staff-user">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.user">User</Translate>
                </Label>
                <AvInput
                  id="staff-user"
                  type="select"
                  className="form-control"
                  name="user.id"
                  value={isCheck&&foundUser&&foundUser.id?foundUser.id:isNew ? users[0] && users[0].id : staffEntity.user?.id}
                  key={isCheck&&foundUser&&foundUser.id?foundUser.id:isNew ? users[0] && users[0].id : staffEntity.user?.id}
                  required
                >
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.login}
                        </option>
                      ))
                    : null}
                </AvInput>
                <AvFeedback>
                  <Translate contentKey="entity.validation.required">This field is required.</Translate>
                </AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="staff-country">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.country">Country</Translate>
                </Label>
                <AvInput id="staff-country" onChange={changeCountry} type="select" className="form-control" name="country.id">
                  <option value="" key="0" />
                  {countries
                    ? countries.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="staff-cstate">
                  <Translate contentKey="hospitalmsappfrontendApp.staff.cstate">Cstate</Translate>
                </Label>
                <AvInput id="staff-cstate" type="select" className="form-control" name="cstate.id">
                  <option value="" key="0" />
                  {cStates
                    ? cStates.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/staff" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  foundUser: storeState.userSearchState.user,
  users: storeState.userManagement.users,
  countries: storeState.country.entities,
  cStates: storeState.cState.entities,
  staffEntity: storeState.staff.entity,
  loading: storeState.staff.loading,
  updating: storeState.staff.updating,
  updateSuccess: storeState.staff.updateSuccess,
});

const mapDispatchToProps = {
  getEntitiesByCountryId,
  getCountries,
  getCStates,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getUsersNoPatient,
  searchUser,
  resetUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StaffUpdate);
