import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat, logInfo } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntityByAppointmentId } from '../paymentinvoice/invoice.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICStateDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const InvoiceDetail = (props: ICStateDetailProps) => {
    useEffect(() => {
        props.getEntityByAppointmentId(props.match.params.id);
    }, []);




    const { invoiceEntity } = props;
    return (
        <div>

            <Table responsive>
                <thead>

                    <tr style={{ textAlign: "right"}}>
                        <th style={{ fontSize: "45px" }} >
                            INVOICE
                        </th>
                        <th />
                    </tr>

                    <tr>
                        <th >
                            <img width="300" height="75" src="content/images/logo.png"></img>
                        </th>
                        <th />
                    </tr>

                    <tr>
                    <th style={{ fontSize: "25px" }} >
                            Date:
                        </th>

                        <th style={{ fontSize: "25px" }} >
                            {invoiceEntity && invoiceEntity.createdDate ? invoiceEntity.createdDate : ""}
                        </th>
                        <th />
                    </tr>


                    <tr>
                    <th style={{ fontSize: "25px" }} >
                            SSN:
                        </th>

                        <th style={{ fontSize: "25px" }} >
                            {invoiceEntity && invoiceEntity.ssn ? invoiceEntity.ssn : ""}
                        </th>
                        <th />
                    </tr>

                    <tr>
                    <th style={{ fontSize: "25px" }} >
                            Name:
                        </th>

                        <th style={{ fontSize: "25px" }} >
                            {invoiceEntity && invoiceEntity.billName ? invoiceEntity.billName : ""}
                        </th>
                        <th />
                    </tr>

                    <tr>
                    <th style={{ fontSize: "25px" }} >
                            Payment Method:
                        </th>

                        <th style={{ fontSize: "25px" }} >
                            {invoiceEntity && invoiceEntity.paymentMethod ? invoiceEntity.paymentMethod : ""}
                        </th>
                        <th />
                    </tr>

                    <tr>
                    <th style={{ fontSize: "25px" }} >
                            Total Cost:
                        </th>

                        <th style={{ fontSize: "25px" }} >
                            {invoiceEntity && invoiceEntity.totalCost ? invoiceEntity.totalCost+"$" : ""}
                        </th>
                        <th />
                    </tr>

                    <tr>
                        <td>

                        </td>
                    </tr>
                    <br />
                </thead>

            </Table>

        </div>
    );
};

const mapStateToProps = ({ invoice }: IRootState) => ({
    invoiceEntity: invoice.entity,
});

const mapDispatchToProps = { getEntityByAppointmentId };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
