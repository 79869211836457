import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Input, Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getAppointmentsByPatientId } from './physician-appointment.reducer';
import { IAppointment } from 'app/shared/model/appointment.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';

export interface IAppointmentProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const AppointmentList = (props: IAppointmentProps) => {

    useEffect(() => {
        props.getAppointmentsByPatientId(props.match.params.id);
    }, []);

    const { appointmentList, match, loading } = props;
    return (
        <div>
            <h2 id="appointment-heading">Appointments</h2>
            <div className="table-responsive">
                {appointmentList && appointmentList.length > 0 ? (
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.startDate">Start Date</Translate>{' '}
                                    <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.endDate">End Date</Translate> <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.anamnesis">Anamnesis</Translate>{' '}
                                    <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.treatment">Treatment</Translate>{' '}
                                    <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.diagnosis">Diagnosis</Translate>{' '}
                                    <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.prescription">Prescription</Translate>{' '}
                                    <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.description">Description</Translate>{' '}
                                    <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.createdDate">Created Date</Translate>{' '}
                                    <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.physician">Physician</Translate>{' '}
                                    <FontAwesomeIcon icon="sort" />
                                </th>
                                <th>
                                    <Translate contentKey="hospitalmsappfrontendApp.appointment.patient">Patient</Translate> <FontAwesomeIcon icon="sort" />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {appointmentList.map((appointment, i) => (
                                <tr key={`entity-${i}`}>
                                    <td>
                                         {appointment.id}
                                    </td>
                                    <td>
                                        {appointment.startDate ? <TextFormat type="date" value={appointment.startDate} format={APP_DATE_FORMAT} /> : null}
                                    </td>
                                    <td>{appointment.endDate ? <TextFormat type="date" value={appointment.endDate} format={APP_DATE_FORMAT} /> : null}</td>
                                    <td>
                                        <Translate contentKey={`hospitalmsappfrontendApp.AppointmentStatus.${appointment.status}`} />
                                    </td>
                                    <td>{appointment.anamnesis}</td>
                                    <td>{appointment.treatment}</td>
                                    <td>{appointment.diagnosis}</td>
                                    <td>{appointment.prescription}</td>
                                    <td>{appointment.description}</td>
                                    <td>
                                        {appointment.createdDate ? <TextFormat type="date" value={appointment.createdDate} format={APP_DATE_FORMAT} /> : null}
                                    </td>
                                    <td>
                                        {appointment.physician ? <Link to={`/physician-detail/${appointment.physician.id}`}>{appointment.physician.firstName}</Link> : ''}
                                    </td>
                                    <td>{appointment.patient ? <Link to={`/patient-detail/${appointment.patient.id}`}>{appointment.patient.firstName}</Link> : ''}</td>
                                    <td className="text-right">
                                        <div className="btn-group flex-btn-group-container">
                                        <Button style={{ marginRight: "5px" }}
                                                        tag={Link}
                                                        to={"/appointment-update" + `/${appointment.id}`}
                                                        color="warning"
                                                        size="sm"
                                                    >
                                                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                                                        <span className="d-none d-md-inline">
                                                            <Translate contentKey="entity.action.edit">Edit</Translate>
                                                        </span>
                                                    </Button> 
                                            {
                                                !props.isPhysician ?
                                                    <Button
                                                        tag={Link}
                                                        to={"/tests/appointment" + `/${appointment.id}`}
                                                        color="primary"
                                                        size="sm"
                                                    >
                                                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                                                        <span className="d-none d-md-inline">
                                                            <Translate contentKey="hospitalmsappfrontendApp.cTest.showtest">Show Tests</Translate>
                                                        </span>
                                                    </Button>:null
                                            }

                                            {appointment.status==="COMPLETED" ?
                                                <Button style={{ marginLeft: "5px" }}
                                                    tag={Link}
                                                    to={"/payment-detail/" + appointment.id}
                                                    color="success"
                                                    size="sm"
                                                >
                                                    <FontAwesomeIcon icon={faMoneyBill} />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="hospitalmsappfrontendApp.bill.payment">Payment Process</Translate>
                                                    </span>
                                                </Button>

                                                : null}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    !loading && (
                        <div className="alert alert-warning">
                            <Translate contentKey="hospitalmsappfrontendApp.appointment.home.notFound">No Appointments found</Translate>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication, appointmentPhysician }: IRootState) => ({
    appointmentList: appointmentPhysician.entities,
    loading: appointmentPhysician.loading,
    userId: authentication.account.id,
    isStaff: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.STAFF]),
    isPhysician: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PHYSICIAN]),
});

const mapDispatchToProps = {
    getAppointmentsByPatientId
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentList);
