import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Input, Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getInPatientListByDate, getInPatientListByDateUserId } from './in-patient.reducer';
import { IInPatient } from 'app/shared/model/in-patient.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import moment from 'moment';
import { toast } from 'react-toastify';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';

export interface IInPatientProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const PhysicianInPatient = (props: IInPatientProps) => {
  const beforeAWeek = (): string => {
    // Today + 1 day - needed if the current day must be included
    const day: Date = new Date();
    day.setDate(day.getDate() + 1);
    const toDate = new Date(day.getFullYear(), day.getMonth(), day.getDate()-7);
    return toDate.toISOString().slice(0, 10);
  };

  const afterAWeek = (): string => {
    // Today + 1 day - needed if the current day must be included
    const day: Date = new Date();
    day.setDate(day.getDate());
    const toDate = new Date(day.getFullYear(), day.getMonth(), day.getDate()+7);
    return toDate.toISOString().slice(0, 10);
  };

  const [fromDate, setFromDate] = useState(beforeAWeek());
  const [toDate, setToDate] = useState(afterAWeek());

  const diff2Dates=()=>{
    const diff=moment(toDate).diff(moment(fromDate), 'days');
    return diff;
  }

  const onChangeFromDate = evt => setFromDate(evt.target.value);

  const onChangeToDate = evt => setToDate(evt.target.value);

  const getAllEntities = () => {
    if(diff2Dates()<15){
    if (props.isPhysician)
      props.getInPatientListByDateUserId(fromDate, toDate, props.userId);
    else
      props.getInPatientListByDate(fromDate, toDate);
    }
      else{
        toast.warn("Days between begin date and end date must be max 15 days");
      }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getAllEntities();
 
  }, [fromDate, toDate]);

  const { inPatientList, match, loading } = props;
  return (
    <div>
      <h2 id="in-patient-heading">
        <Translate contentKey="hospitalmsappfrontendApp.inPatient.home.title">In Patients</Translate>
      </h2>

      <span>
        <Translate contentKey="audits.filter.from">from</Translate>
      </span>
      <Input type="date" value={fromDate} onChange={onChangeFromDate} name="fromDate" id="fromDate" />
      <span>
        <Translate contentKey="audits.filter.to">to</Translate>
      </span>
      <Input type="date" value={toDate} onChange={onChangeToDate} name="toDate" id="toDate" />

      <div className="table-responsive">
        {inPatientList && inPatientList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="global.field.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.startDate">Start Date</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.endDate">End Date</Translate>
                </th>

                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.status">Status</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.description">Description</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.createdDate">Created Date</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.room">Room</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.appointment">Appointment</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.inPatient.patient">Patient</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {inPatientList.map((inPatient, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                      {inPatient.id}
                  </td>
                  <td>{inPatient.startDate ? <TextFormat type="date" value={inPatient.startDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{inPatient.endDate ? <TextFormat type="date" value={inPatient.endDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{inPatient.status}</td>
                  <td>{inPatient.description}</td>
                  <td>
                    {inPatient.createdDate ? <TextFormat type="date" value={inPatient.createdDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{inPatient.room ? inPatient.room.roomNumber : ''}</td>
                  <td>
                    {inPatient.appointment ? <Link to={`appointment-detail/${inPatient.appointment.id}`}>{inPatient.appointment.id}</Link> : ''}
                  </td>
                  
                  <td>{inPatient.patient ? <Link to={`patient-detail/${inPatient.patient.id}`}>{inPatient.patient.user.ssn+":"+inPatient.patient.firstName+" "+inPatient.patient.lastName}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={"/in-patient" + `/${inPatient.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>

                      {
                        props.isAdmin?
                         <Button tag={Link} to={"/in-patient" + `/${inPatient.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>:null
                      }
                     
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hospitalmsappfrontendApp.inPatient.home.notFound">No In Patients found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inPatient, authentication }: IRootState) => ({
  inPatientList: inPatient.entities,
  loading: inPatient.loading,
  userId: authentication.account.id,
  isPhysician: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PHYSICIAN]),
  isStaff: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.STAFF]),
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN])
});

const mapDispatchToProps = {
  getInPatientListByDateUserId,
  getInPatientListByDate
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianInPatient);
