import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPaymentInfo, defaultValue } from 'app/shared/model/payment.model';

export const ACTION_TYPES = {
  FETCH_PAYMENT_INFO: 'invoice/FETCH_PAYMENT_INFO',
  RESET: 'invoice/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPaymentInfo>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type PaymentInvoiceState = Readonly<typeof initialState>;

// Reducer

export default (state: PaymentInvoiceState = initialState, action): PaymentInvoiceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAYMENT_INFO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PAYMENT_INFO):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENT_INFO):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/appointments/payment';

export const getEntity: ICrudGetAction<IPaymentInfo> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PAYMENT_INFO,
    payload: axios.get<IPaymentInfo>(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
