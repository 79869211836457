import { Moment } from 'moment';
import { ICTestResult } from 'app/shared/model/c-test-result.model';
import { IAppointment } from 'app/shared/model/appointment.model';

export interface ICTest {
  id?: number;
  name?: string;
  description?: string;
  date?: string;
  createdDate?: string;
  cTestResults?: ICTestResult[];
  appointment?: IAppointment;
}

export const defaultValue: Readonly<ICTest> = {};
