import { Moment } from 'moment';
import { RoomType } from 'app/shared/model/enumerations/room-type.model';

export interface IRoom {
  id?: number;
  roomNumber?: number;
  roomType?: RoomType;
  status?: boolean;
  price?: number;
  description?: string;
  createdDate?: string;
}

export const defaultValue: Readonly<IRoom> = {
  status: false,
};
