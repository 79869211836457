import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from './menu-components';

import {
  faHospitalUser, faCalendarWeek, faBed, faUserInjured
} from '@fortawesome/free-solid-svg-icons';

export const PhysicianMenu = props => (
  <NavDropdown
  
    icon="th-list"
    name={translate('global.menu.mypages')}
    id="entity-menu"
    style={{ maxHeight: '80vh', overflow: 'auto'}}
  >
  <MenuItem toggle={props.toggle}  icon={faCalendarWeek} to="/physician-appointment">
      <Translate contentKey="global.menu.entities.myappointments" />
    </MenuItem>

    <MenuItem toggle={props.toggle}   icon={faUserInjured} to="/physician-patient">
      <Translate contentKey="global.menu.entities.mypatients" />
    </MenuItem>

    <MenuItem toggle={props.toggle}   icon={faBed} to="/ps-in-patient">
      <Translate contentKey="global.menu.entities.myinpatients" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
