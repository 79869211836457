import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from './menu-components';

import {
    faHospitalUser, faCalendarWeek,faDollyFlatbed, faSearch
} from '@fortawesome/free-solid-svg-icons';

export const StaffMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.mypages')}
    id="entity-menu"
    style={{ maxHeight: '80vh', overflow: 'auto'}}
  >
    <MenuItem toggle={props.toggle}   icon={faSearch} to="/search-patient">
      <Translate contentKey="global.menu.entities.patientsearch" />
    </MenuItem>

    <MenuItem toggle={props.toggle}   icon={faDollyFlatbed} to="/ps-in-patient">
      <Translate contentKey="global.menu.entities.inPatient" />
    </MenuItem>

    

    
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
