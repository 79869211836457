import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import About from './tpui/About';
import Services from './tpui/Services';
import Departments from './tpui/Departments';
import Doctors from './tpui/Doctors';
import Contact from './tpui/Contact';
import physician from './entities/physician/physician';
import physicianappointment from "./entities/physicianappointment/physicianappointment";
import patient from "./entities/patient";
import appointment from './entities/appointment/appointment-update';
import physiciantest from './entities/physicianappointment/physiciantest';

import patientappointment from './entities/patientappointment/patient-appointment';

import appointmenttests from './entities/appointmenttests/appointment-tests';

import patienttestresults from './entities/patienttestresults/patient-test-result';

import physicianpatient from './entities/physicianpatient/physician-patient';

import physicianinpatient from './entities/in-patient/physician-in-patient';
import physicianAppointmentDetail  from './entities/physicianappointment/appointment-detail';
import patientAppointmentDetail  from './entities/physicianpatient/patient-detail';
import physicianAppointmentUpdate from './entities/physicianappointment/appointment-update';

import PatientSearch from './entities/physicianpatient/patient-search';
import patientUpdate from './entities/physicianpatient/patient-update';

import InPatient from './entities/in-patient';

import patientAppointments from './entities/physicianappointment/appointment-list';

import ctestResultUpdate from './entities/patienttestresults/c-test-result-update';

import paymentInvoiceDetail from './entities/paymentinvoice/payment-invoice-detail';

import invoiceUpdate from './entities/paymentinvoice/invoice-update';
import invoiceShow from './entities/paymentinvoice/invoice-show';

import patientInvoiceShow from './entities/patientappointment/patient-invoice-show'

import physicianDetail from './entities/physicianpatient/ps-physician-detail'

import makeAppointment from './tpui/apporequest/appo.request'

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => (
  <div className="view-routes">
    <Switch>
      <ErrorBoundaryRoute path="/" exact component={Home} />
      <ErrorBoundaryRoute path="/home" exact component={Home} />
      <ErrorBoundaryRoute path='/about'  component={About} />
      <ErrorBoundaryRoute path='/service' component={Services} />
      <ErrorBoundaryRoute path='/departments' component={Departments} />
      <ErrorBoundaryRoute path='/doctors' component={Doctors} />
      <ErrorBoundaryRoute path='/contact' component={Contact} />


      <ErrorBoundaryRoute path="/login" component={Login} />
      <ErrorBoundaryRoute path="/logout" component={Logout} />
      <ErrorBoundaryRoute path="/account/register" component={Register} />
      <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
      <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
      <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
      <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.STAFF,AUTHORITIES.PATIENT,AUTHORITIES.PHYSICIAN,AUTHORITIES.ADMIN, AUTHORITIES.USER]} />

      <PrivateRoute path="/appointment-detail/:id" component={physicianAppointmentDetail} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} />
      <PrivateRoute path="/patient-detail/:id" component={patientAppointmentDetail} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF,AUTHORITIES.PATIENT]} />
      
      
      <PrivateRoute path="/appointment-update/:id" component={physicianAppointmentUpdate} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} />
      <PrivateRoute path="/physician-appointment" component={physicianappointment} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} />
      <PrivateRoute path="/physician-patient" component={physicianpatient} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN]} />
      <PrivateRoute path="/physician_test/:id" component={physiciantest} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN]} />

      <PrivateRoute path="/patient-appointment" component={patientappointment} hasAnyAuthorities={[AUTHORITIES.PATIENT]} />
      <PrivateRoute path="/tests/appointment/:id" component={appointmenttests} hasAnyAuthorities={[AUTHORITIES.PATIENT,AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} />

      <PrivateRoute path="/testresult/test/:id" component={patienttestresults} hasAnyAuthorities={[AUTHORITIES.PATIENT,AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} />
     

      <PrivateRoute path="/search-patient" component={PatientSearch} hasAnyAuthorities={[AUTHORITIES.STAFF]} />
      <PrivateRoute path="/patient-update/:id" component={patientUpdate} hasAnyAuthorities={[AUTHORITIES.STAFF]} />
      <PrivateRoute path="/patient-new" component={patientUpdate} hasAnyAuthorities={[AUTHORITIES.STAFF]} />

    

      <PrivateRoute path="/in-patient" component={InPatient} hasAnyAuthorities={[AUTHORITIES.ADMIN,AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]}  />
      <PrivateRoute path="/c-test-result-update/:id" component={ctestResultUpdate} hasAnyAuthorities={[AUTHORITIES.STAFF]}  />
  

      <PrivateRoute path="/ps-in-patient" component={physicianinpatient} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} />
      {/* <PrivateRoute path="/physician-in-patient/:id" component={inPatientView} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} />
      <PrivateRoute path="/physician-in-patient/:id/edit"  component={inPatientUpdate} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} />
      <PrivateRoute path="/physician-in-patient/:id/delete"  component={inPatientDelete} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN,AUTHORITIES.STAFF]} /> */}

       <PrivateRoute path="/patient-appointments/:id" component={patientAppointments} hasAnyAuthorities={[AUTHORITIES.STAFF]} />

       <PrivateRoute path="/payment-detail/:id" component={paymentInvoiceDetail} hasAnyAuthorities={[AUTHORITIES.STAFF]} />
       <PrivateRoute path="/invoice-update/:id" component={invoiceUpdate} hasAnyAuthorities={[AUTHORITIES.STAFF]} />
       <PrivateRoute path="/invoice-show/:id" component={invoiceShow} hasAnyAuthorities={[AUTHORITIES.STAFF]} />
       <PrivateRoute path="/patient-invoice-show/:id" component={patientInvoiceShow} hasAnyAuthorities={[AUTHORITIES.PATIENT]} />
       <PrivateRoute path="/physician-detail/:id" component={ physicianDetail} hasAnyAuthorities={[AUTHORITIES.STAFF,AUTHORITIES.PHYSICIAN]} />
       <PrivateRoute path="/make-appointment" component={ makeAppointment} hasAnyAuthorities={[AUTHORITIES.PATIENT]} />

      <PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />

     
      
      {/* <PrivateRoute path="/physician_test" component={physicianappointment} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN]} /> */}
      {/* <PrivateRoute path="/physician_test" component={physicianappointment} hasAnyAuthorities={[AUTHORITIES.PHYSICIAN]} /> */}

      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  </div>
);

export default Routes;
