import * as React from 'react';
import { useEffect } from 'react';

export interface IAppProps {
  title?: string;
}

export default function About(props: IAppProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section id="about" className="about subpage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
              <a href="" className="glightbox play-btn mb-4"></a>
            </div>

            <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
              <h3>Chief Physician&apos;s Message</h3>
              <p>
                <strong>
                  Health; It is a matter of great importance that we have difficulty in understanding when we have it, but that can change
                  our quality of life and standards when we lose it. Health; It is of great importance not only to continue our day, but
                  also to lead our lives in a normal, normal and happy way. That&apos;s why, as {process.env.REACT_APP_COMPANY}, we are always at your side
                  with an expert staff, hospital staff and technical equipment, who work without forgetting the importance of health for a
                  moment and provide the standards required for the continuation of your life quality and happiness. We are happy to welcome
                  you with the principle of &quot;Health Every Time&quot;, which is the priority of our hospital, and to offer you health
                  services with concrete solutions. Wishing you a long and healthy life with your loved ones.
                </strong>
              </p>

              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-happy"></i>
                </div>
                <h4 className="title">&quot;Patient satisfaction&quot;is our basic principle</h4>
                {/* <h2 className="description">&quot;patient satisfaction&quot;is our basic principle</h2> */}
              </div>

              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-home-heart"></i>
                </div>
                <h4 className="title">Employees who dedicate themselves to health</h4>
                {/* <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p> */}
              </div>

              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxs-user-account"></i>
                </div>
                <h4 className="title">Care, compassion, honesty and quality</h4>
                {/* <p className="description">Explicabo est voluptatum asperiores consequatur magnam. Et veritatis odit. Sunt aut deserunt minus aut eligendi omnis</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Services/>
    <Departments/>
    <Gallery/> */}
    </div>
  );
}
