import { Moment } from 'moment';
import { ICTestItem } from 'app/shared/model/c-test-item.model';
import { ICTest } from 'app/shared/model/c-test.model';

export interface ICTestResult {
  id?: number;
  date?: string;
  result?: string;
  description?: string;
  createdDate?: string;
  ctestItem?: ICTestItem;
  ctest?: ICTest;
}

export const defaultValue: Readonly<ICTestResult> = {};
