import * as React from 'react';
import {useEffect} from 'react';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';

export interface IAppProps {
  title?:string;
}

export default function Services (props: IAppProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
    <section id="services" className="services subpage">
    <div className="container">

      <div className="section-title">
        <h2>Services</h2>
         <img src="content/assets/img/services.jpg" className="center"/>
        <p>At {process.env.REACT_APP_COMPANY} Hospital we offer both inpatient and outpatient services at our campus and throughout our community partner locations.

          We are home to the largest emergency department  in the Med Street – providing access to lifesaving emergency and trauma care 24/7. Our Family Birth Center and  neonatal intensive care unit (NICU) give babies the safest start to life. Leading cancer care, cardiology services, orthopedic care and advanced robotic surgery — among many other healthcare services — are available to all who turn to us for care</p>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div className="icon-box">
            <div className="icon"><i className="fas fa-heartbeat"></i></div>
            <h4>Cardiology Services</h4>
            <p>All tests about cardiology; ECG,ECHO etc.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
          <div className="icon-box">
            <div className="icon"><i className="fas fa-stethoscope"></i></div>
            <h4>Checkup</h4>
            <p>It is recommended to regularly check-up at least once a year for all people who have no complaints or diseases.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
          <div className="icon-box">
            <div className="icon"><i className="fas fa-ambulance"></i></div>
            <h4><a href="">Ambulance Services</a></h4>
            <p>Ambulance services is at your service 24/7</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div className="icon-box">
            <div className="icon"><i className="fas fa-dna"></i></div>
            <h4><a href="">DNA Analysis</a></h4>
            <p>This test analyzes fetal DNA found in a pregnant woman &apos;s blood during the first trimester</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div className="icon-box">
            <div className="icon"><i className="fas fa-wheelchair"></i></div>
            <h4><a href="">Disabled-friendly</a></h4>
            <p>Our hospital is comfortable for disabled patients</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div className="icon-box">
            <div className="icon"><i className="fas fa-medkit"></i></div>
            <h4><a href="">Mobile Physician Services</a></h4>
            <p>Mobile Physician Services brings the doctor &apos;s office to the comfort and convenience of your home</p>
          </div>
        </div>

      </div>

    </div>
  </section>
  </div>
  );
}
