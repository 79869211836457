import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAppointment, defaultValue } from 'app/shared/model/appointment.model';
import { ICTest } from 'app/shared/model/c-test.model';

export const ACTION_TYPES = {
  FETCH_APPOINTMENT_TESTS_LIST: 'ps-appointment/FETCH_APPOINTMENT_TESTS_LIST',
  RESET: 'ps-appointment/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICTest>,
};

export type AppointmentTestsState = Readonly<typeof initialState>;

// Reducer

export default (state: AppointmentTestsState = initialState, action): AppointmentTestsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_APPOINTMENT_TESTS_LIST):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_APPOINTMENT_TESTS_LIST):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPOINTMENT_TESTS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/c-tests/appointment';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getEntities: ICrudGetAllAction<ICTest> = id => ({
  type: ACTION_TYPES.FETCH_APPOINTMENT_TESTS_LIST,
  payload: axios.get<ICTest>(apiUrl + '/' + id),
});
