import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './c-state.reducer';
import { ICState } from 'app/shared/model/c-state.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

export interface ICStateProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const CState = (props: ICStateProps) => {
const [paginationState, setPaginationState] = useState(
  overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
);

const getAllEntities = () => {
  props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
};

const sortEntities = () => {
  getAllEntities();
  const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
  if (props.location.search !== endURL) {
    props.history.push(`${props.location.pathname}${endURL}`);
  }
};

useEffect(() => {
  sortEntities();
}, [paginationState.activePage, paginationState.order, paginationState.sort]);

useEffect(() => {
  const params = new URLSearchParams(props.location.search);
  const page = params.get('page');
  const sort = params.get('sort');
  if (page && sort) {
    const sortSplit = sort.split(',');
    setPaginationState({
      ...paginationState,
      activePage: +page,
      sort: sortSplit[0],
      order: sortSplit[1],
    });
  }
}, [props.location.search]);

const sort = p => () => {
  setPaginationState({
    ...paginationState,
    order: paginationState.order === 'asc' ? 'desc' : 'asc',
    sort: p,
  });
};

const handlePagination = currentPage =>
  setPaginationState({
    ...paginationState,
    activePage: currentPage,
  });

const { cStateList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="c-state-heading">
        <Translate contentKey="hospitalmsappfrontendApp.cState.home.title">C States</Translate>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="hospitalmsappfrontendApp.cState.home.createLabel">Create new C State</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        {cStateList && cStateList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="global.field.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.cState.name">Name</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.cState.createdDate">Created Date</Translate>
                </th>
                <th>
                  <Translate contentKey="hospitalmsappfrontendApp.cState.state">State</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {cStateList.map((cState, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${cState.id}`} color="link" size="sm">
                      {cState.id}
                    </Button>
                  </td>
                  <td>{cState.name}</td>
                  <td>{cState.createdDate ? <TextFormat type="date" value={cState.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{cState.country ? <Link to={`country/${cState.country.id}`}>{cState.country.name}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${cState.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${cState.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${cState.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hospitalmsappfrontendApp.patient.home.notFound">No Patients found</Translate>
            </div>
          )
        )}
      </div>
      {props.totalItems ? (
        <div className={cStateList && cStateList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ cState }: IRootState) => ({
  cStateList: cState.entities,
  loading: cState.loading,
  totalItems: cState.totalItems,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CState);
