import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, logInfo } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICTestItem, defaultValue } from 'app/shared/model/c-test-item.model';

export const ACTION_TYPES = {
  FETCH_CTESTITEM_LIST: 'cTestItem/FETCH_CTESTITEM_LIST',
  FETCH_CTESTITEM: 'cTestItem/FETCH_CTESTITEM',
  CREATE_CTESTITEM: 'cTestItem/CREATE_CTESTITEM',
  UPDATE_CTESTITEM: 'cTestItem/UPDATE_CTESTITEM',
  DELETE_CTESTITEM: 'cTestItem/DELETE_CTESTITEM',
  FETCH_CTESTITEM_LIST_NOPAGE: 'cTestItem/FETCH_CTESTITEM_LIST_NOPAGE',
  RESET: 'cTestItem/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICTestItem>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type CTestItemState = Readonly<typeof initialState>;

// Reducer

export default (state: CTestItemState = initialState, action): CTestItemState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CTESTITEM_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CTESTITEM_LIST_NOPAGE):
    case REQUEST(ACTION_TYPES.FETCH_CTESTITEM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CTESTITEM):
    case REQUEST(ACTION_TYPES.UPDATE_CTESTITEM):
    case REQUEST(ACTION_TYPES.DELETE_CTESTITEM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CTESTITEM_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CTESTITEM_LIST_NOPAGE):
    case FAILURE(ACTION_TYPES.FETCH_CTESTITEM):
    case FAILURE(ACTION_TYPES.CREATE_CTESTITEM):
    case FAILURE(ACTION_TYPES.UPDATE_CTESTITEM):
    case FAILURE(ACTION_TYPES.DELETE_CTESTITEM):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CTESTITEM_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_CTESTITEM_LIST_NOPAGE): {
      logInfo('datas:' + action.payload.data);
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_CTESTITEM):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CTESTITEM):
    case SUCCESS(ACTION_TYPES.UPDATE_CTESTITEM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CTESTITEM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = process.env.REACT_APP_API_URL + '/c-test-items';

// Actions

export const getEntities: ICrudGetAllAction<ICTestItem> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CTESTITEM_LIST,
    payload: axios.get<ICTestItem>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntitiesNoPaging: ICrudGetAllAction<ICTestItem> = () => {
  const requestUrl = `${apiUrl + '/all'}`;
  return {
    type: ACTION_TYPES.FETCH_CTESTITEM_LIST_NOPAGE,
    payload: axios.get<ICTestItem>(requestUrl),
  };
};

export const getEntity: ICrudGetAction<ICTestItem> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CTESTITEM,
    payload: axios.get<ICTestItem>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ICTestItem> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CTESTITEM,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICTestItem> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CTESTITEM,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICTestItem> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CTESTITEM,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
