import appointment from 'app/entities/appointment/appointment';
import * as React from 'react';
import {useEffect} from 'react';
import About from './About';
import Appointment from './apporequest/appo.request';
import Contact from './Contact';
import Counts from './Counts';
import Departments from './Departments';
import Doctors from './Doctors';
import Faq from './Faq';
import Gallery from './Gallery';
import Services from './Services';
import Testimonials from './Testimonials';
import WhyUs from './WhyUs';

export interface IAppProps {
  title?:string
}

export default function Main (props: IAppProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main id="main">
        <WhyUs/>
        {/* <About/> */}
        <Counts/>
        {/* <Services/> */}
        
        {/* <Departments/> */}
        {/* <Doctors/> */}
        {/* <Faq/> */}
        {/* <Testimonials/> */}
        <Gallery/>
        <Appointment/>
    
    </main>
  );
}
