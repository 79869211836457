import { Moment } from 'moment';
import { IAppointment } from 'app/shared/model/appointment.model';
import { IRoom } from 'app/shared/model/room.model';
import { IPatient } from 'app/shared/model/patient.model';
import { InPatientStatus } from './enumerations/in-patient-status.model';

export interface IInPatient {
  id?: number;
  startDate?: string;
  endDate?: string;
  description?: string;
  createdDate?: string;
  appointment?: IAppointment;
  room?: IRoom;
  status?: InPatientStatus;
  patient?: IPatient;
}

export const defaultValue: Readonly<IInPatient> = {};
