import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);


export const now =()=>   moment().endOf('day').toJSON().split('T')[0];

export const max =()=>   moment().add(100, 'year').endOf('year');

export const convertDate = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT).split('T')[0]: null);