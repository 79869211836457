import * as React from 'react';

export interface IAppProps {
  title?:string
}
export default function Gallery (props: IAppProps) {
  return (
    <section id="gallery" className="gallery">
    <div className="container">

      <div className="section-title">
        <h2>Gallery</h2>
      </div>
    </div>

    <div className="container-fluid">
      <div className="row no-gutters">

        <div className="col-lg-3 col-md-4">
          <div className="gallery-item">
            <a href="content/assets/img/gallery/gallery-1.jpg" className="galelry-lightbox">
              <img src="content/assets/img/gallery/gallery-1.jpg" alt="" className="img-fluid"/>
            </a>
          </div>
        </div>

        <div className="col-lg-3 col-md-4">
          <div className="gallery-item">
            <a href="content/assets/img/gallery/gallery-2.jpg" className="galelry-lightbox">
              <img src="content/assets/img/gallery/gallery-2.jpg" alt="" className="img-fluid"/>
            </a>
          </div>
        </div>

        <div className="col-lg-3 col-md-4">
          <div className="gallery-item">
            <a href="content/assets/img/gallery/gallery-3.jpg" className="galelry-lightbox">
              <img src="content/assets/img/gallery/gallery-3.jpg" alt="" className="img-fluid"/>
            </a>
          </div>
        </div>

        <div className="col-lg-3 col-md-4">
          <div className="gallery-item">
            <a href="content/assets/img/gallery/gallery-4.jpg" className="galelry-lightbox">
              <img src="content/assets/img/gallery/gallery-4.jpg" alt="" className="img-fluid"/>
            </a>
          </div>
        </div>

        <div className="col-lg-3 col-md-4">
          <div className="gallery-item">
            <a href="content/assets/img/gallery/gallery-5.jpg" className="galelry-lightbox">
              <img src="content/assets/img/gallery/gallery-5.jpg" alt="" className="img-fluid"/>
            </a>
          </div>
        </div>

        <div className="col-lg-3 col-md-4">
          <div className="gallery-item">
            <a href="content/assets/img/gallery/gallery-6.jpg" className="galelry-lightbox">
              <img src="content/assets/img/gallery/gallery-6.jpg" alt="" className="img-fluid"/>
            </a>
          </div>
        </div>

        <div className="col-lg-3 col-md-4">
          <div className="gallery-item">
            <a href="content/assets/img/gallery/gallery-7.jpg" className="galelry-lightbox">
              <img src="content/assets/img/gallery/gallery-7.jpg" alt="" className="img-fluid"/>
            </a>
          </div>
        </div>

        <div className="col-lg-3 col-md-4">
          <div className="gallery-item">
            <a href="content/assets/img/gallery/gallery-8.jpg" className="galelry-lightbox">
              <img src="content/assets/img/gallery/gallery-8.jpg" alt="" className="img-fluid"/>
            </a>
          </div>
        </div>

      </div>

    </div>
  </section>
  );
}
